import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Switch, Input, message, Form } from 'antd';
import { ApiSaveOfflineConfig, ApiQueryOfflineConfig } from '@/request/api';
import './index.scss'

export default function PaymentMethods () {
  const { i18n } = globalState;
  const [showConfig, setShowConfig] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [perValue, setPerValue] = useState(1);
  const [perError, setPerError] = useState(false);
  const [totalValue, setTotalValue] = useState(1);
  const [totalError, setTotalError] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);




  const queryOfflineConfig = () => {
    ApiQueryOfflineConfig({
      configKeys:['offlineSwitch', 'perTransactionLimit', 'totalTransactionLimit']
    }).then(res => {
      const { offlineSwitch, perTransactionLimit = 1, totalTransactionLimit = 1 } = res?.data.configKeyValueMap;
      setChecked(offlineSwitch === '1');
      if (offlineSwitch === '1') {
        setShowConfig(true);
      } else {
        setShowConfig(false);
      }
      setPerValue(parseInt(perTransactionLimit) ? parseInt(perTransactionLimit) : 1);
      setTotalValue(parseInt(totalTransactionLimit) ? parseInt(totalTransactionLimit) : 1);
    })
  }

  const saveOfflineConfig = () => {
    ApiSaveOfflineConfig({
      configKeyValueMap:{
        offlineSwitch: checked ? '1' : '0',
        perTransactionLimit: perValue.toString(),
        totalTransactionLimit: totalValue.toString()
      }
    }).then(res => {
      if (res?.success) {
        setIsEdit(false);
        message.success('success');
      }
    })
  }

  const handlePerChange = (e) => {
    const inputValue = e.target.value;
    // 仅允许输入数字和小数点
    if (/^\d*\.?\d*$/.test(inputValue)) {
      // 检查输入是否为空
      if (inputValue === '') {
        setPerValue(0); // 清空或设置为默认值
        setPerError(false); // 不显示错误
      } else {
        // 检查数字范围
        const numericValue = parseFloat(inputValue); // 使用 parseFloat 更适合处理小数
        setPerValue(numericValue);
        if (numericValue <= 0 || numericValue > 1000) {
          setPerError(true);
        } else {
          setPerError(false);
        }
        if (numericValue > totalValue) {
          setLimitError(true);
        } else {
          setLimitError(false);
        }
      }
    }
  };

  const handleTotalChange = (e) => {
    const inputValue = e.target.value;

    // 仅允许输入数字和小数点
    if (/^\d*\.?\d*$/.test(inputValue)) {
      // 检查输入是否为空
      if (inputValue === '') {
        setTotalValue(0); // 清空或设置为默认值
        setTotalError(false); // 不显示错误
      } else {
        // 检查数字范围
        const numericValue = parseFloat(inputValue); // 使用 parseFloat 更适合处理小数
        setTotalValue(numericValue);
        if (numericValue <= 0 || numericValue > 10000) {
          setTotalError(true);
        } else {
          setTotalError(false);
        }
        if (numericValue < perValue) {
          setLimitError(true);
        } else {
          setLimitError(false);
        }
      }
    }
  };


  useEffect(() => {
    queryOfflineConfig()
  }, []);

  const handleModalOk = () => {
    setIsModalOpen(false);
    setShowConfig(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setChecked(false);
  };

  const onSwitchChange = (checked: boolean) => {
    setChecked(checked);
    if (!checked) {
      setPerValue(0);
      setTotalValue(0);
      setShowConfig(false)
    } else {
      setIsModalOpen(true);
      setIsEdit(true);
    }
  };

  const changeEdit = () => {
    setIsEdit(!isEdit);
  }

  const saveConfig = () => {
    console.log(checked, perValue, totalValue)
    if (checked) {
      if (perValue <= 0 || perValue > 1000) {
        setPerError(true);
        return
      } else {
        setPerError(false);
      }
      if (totalValue <= 0 || totalValue > 10000) {
        setTotalError(true);
        return
      } else {
        setTotalError(false);
      }
      if (totalValue < perValue) {
        setLimitError(true);
        return
      } else {
        setLimitError(false);
      }
    }

    saveOfflineConfig();
  }


  const cancelConfig = () => {
    // setChecked(false);
    // setShowConfig(false)
    // setPerValue(0);
    // setTotalValue(0);
    // setTimeout(() => {
    //   saveOfflineConfig()
    // }, 3000);
    queryOfflineConfig()
    setIsEdit(false);
  }


  return (
    <div className="offline-config">
      <div className="m-title">
        <div className="title">
          { i18n.t('dashboard_offline_payment_page_title') }
        </div>
        { isEdit && <div className="actions">
          <Button onClick={ cancelConfig } style={{ marginRight: '20px' }}>{ i18n.t('dashboard_offline_payment_limit_Cancel') }</Button>
          <Button type="primary" onClick={ saveConfig }>{ i18n.t('dashboard_offline_payment_limit_Save') }</Button>
        </div>}
        { !isEdit && checked && <div className="actions">
          <Button type="primary" onClick={ changeEdit }>{ i18n.t('edit') }</Button>
        </div>}
      </div>
      <div className="table-wrap">
        <div className='row-container'>
          <div className='name' style={{ marginTop:8 }}>{ i18n.t('dashboard_offline_payment_enable') }</div>
          <div style={{ height:8 }}></div>
          <Switch checked={checked} onChange={onSwitchChange} disabled={!isEdit && checked }/>
        </div>
        { showConfig && <>
          <div className='desc'>{ i18n.t('dashboard_offline_payment_enable_description') }</div>
          <div className=''>
            <div className='name'>{ i18n.t('dashboard_offline_payment_limit_per_transaction_limit') }</div>
            <div className='desc'>{ i18n.t('dashboard_offline_payment_limit_per_transaction_limit_description') }</div>
            <div className='row-container'>
              {/* <div className='input-name'>{ i18n.t('dashboard_offline_payment_limit_per_transaction_limit_maximum_value') }</div>
              <Input placeholder="0.00" prefix={'$'} style={{ width: '128px', height: '34px' }} value={perValue} onChange={handlePerChange}/> */}
              <div className='input-name'>
                { i18n.t('dashboard_offline_payment_limit_per_transaction_limit_maximum_value') }
                <span style={{ color: 'red' }}>*</span> {/* 添加红色星号 */}
              </div>
              <Input
                placeholder="0"
                prefix={'$'}
                style={{ width: '128px', height: '34px' }}
                value={perValue}
                onChange={handlePerChange}
                disabled={!isEdit}
              />
            </div>
            { perError && <div className='error'>{ i18n.t('dashboard_offline_payment_limit_per_transaction_limit_error_message') }</div> }
          </div>
          <div className=''>
            <div className='name'>{ i18n.t('dashboard_offline_payment_limit_total_transaction_limit') }</div>
            <div className='desc'>{ i18n.t('dashboard_offline_payment_limit_total_transaction_limit_description') }</div>
            <div className='row-container'>
              {/* <div className='input-name'>{ i18n.t('dashboard_offline_payment_limit_total_transaction_limit_maximum_value') }</div>
              <Input placeholder="0.00" prefix={'$'}  style={{ width: '128px', height: '34px' }} value={totalValue} onChange={handleTotalChange}/> */}
              <div className='input-name'>
                {i18n.t('dashboard_offline_payment_limit_total_transaction_limit_maximum_value')}
                <span style={{ color: 'red' }}>*</span> {/* 添加红色星号 */}
              </div>
              <Input
                placeholder="0"
                prefix={'$'}
                style={{ width: '128px', height: '34px' }}
                value={totalValue}
                onChange={handleTotalChange}
                disabled={!isEdit}
              />
            </div>
            { totalError && <div className='error'>{ i18n.t('dashboard_offline_payment_limit_total_transaction_limit_error_message_1') }</div>}
            { limitError && <div className='error'>{ i18n.t('dashboard_offline_payment_limit_total_transaction_limit_error_message_2') }</div>}
          </div>
        </>
        }

      </div>
      <Modal title={i18n.t('dashboard_offline_payment_enable_risk_notice_title') } open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel} closable={false} cancelText={i18n.t('dashboard_offline_payment_limit_Cancel')} okText={i18n.t('dashboard_offline_payment_enable_risk_notice_allow')}>
        <p>{ i18n.t('dashboard_offline_payment_enable_risk_notice_content') }</p>
        <ul style={{ margin: '20px 0 20px 20px' }}>
          <li>{ i18n.t('dashboard_offline_payment_enable_risk_notice_content_1') }</li>
          <li>{ i18n.t('dashboard_offline_payment_enable_risk_notice_content_2') }</li>
          <li>{ i18n.t('dashboard_offline_payment_enable_risk_notice_content_3') }</li>
          <li>{ i18n.t('dashboard_offline_payment_enable_risk_notice_content_4') }</li>
        </ul>
        <p>{ i18n.t('dashboard_offline_payment_enable_risk_notice_content_5') }</p>
      </Modal>
    </div>
  )
}