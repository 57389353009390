import React, { useEffect, useRef, useState } from 'react';
import { globalState } from '@/stores';
import PepprDatePicker from '@/Components/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Col, Flex, Row, Spin } from 'antd';
import ReactToPrint from 'react-to-print';
import useGetState from '@/hooks/useGetState';
import { ApiQuerySaleReport } from '@/request/api';
import { getToday } from '@/utils';
import './index.scss'
import TotalSummary from './TotalSummary';
import NetSalesSummary from './NetSalesSummary';
import NetSalesList from './NetSalesList';
import { IParams, IReportData } from './interface';
import SalesCategoryList from '@/Pages/SalesSummary/SalesCategoryList';
import PaymentsList from '@/Pages/SalesSummary/PaymentsList';
import ServiceChargeList from '@/Pages/SalesSummary/ServiceChargeList';
import { SyncOutlined } from '@ant-design/icons';
import { getCookie } from '@/utils';
import InfoCard from './components/InfoCard';
import SalesByDayPart from './SalesByDayPart';
import DayPartSummary from './DayPartSummary';
import SalesChannel from './SalesChannel';
import Comps from './Comps';
import HeaderTitle from '@/Components/HeaderTitle';

import { TableToolbar } from '@/Components/TableToolbar';
import type { FilterItem } from '@/Components/FilterContainer/types';
import { useMobile } from '@/stores/MobileContext';
export default function SalesSummary () {
  const { i18n } = globalState;
  const { isMobile } = useMobile();

  const [loading, setLoading] = useState<boolean>(true)
  const [params, setParams, getParams] = useGetState<IParams>({
    date: [dayjs(), dayjs()]
  })
  const [today, setToday] = useState<Dayjs>(dayjs())
  const [reportData, setReportData] = useState<Partial<IReportData>>({})
  const printRef = useRef(null);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _today = await getToday();
    setToday(_today);
    setParams({ date: [_today, _today] });
    fetchData();
  }

  const handleChangeDate = (values) => {
    setParams({ ...values })
    fetchData();
  }

  const fetchData = async () => {
    const _params = getParams();
    const result = {
      params: {
        req: {
          beginTime: _params.date[0].format('YYYY-MM-DD 00:00:00'),
          endTime: _params.date[1].format('YYYY-MM-DD 23:59:59')
        }
      }
    }
    setLoading(true)
    const res = await ApiQuerySaleReport(result);
    setLoading(false)
    if (res?.code === 0) {
      setReportData(res.data || {});
    }
  }

  const filters: FilterItem[] = React.useMemo(()=>[
    {
      name: 'date',
      type: 'dateRange',
      today
    },
  ], [today]);

  return (
    <div className="sales-summary-warp print-container" ref={ printRef }>
      <Spin size="large" spinning={ loading }>
        <HeaderTitle title={ i18n.t('Sales_pc_summary') } />
        <div className="content-wrap">
          <TableToolbar
            filters={filters}
            filterValue={params}
            printRef={printRef}
            onFilterChange={handleChangeDate}
            onRefresh={fetchData}
          />
          <div className="chart-wrap">
            <TotalSummary item={ reportData.revenueSummaryDTO }/>
            <NetSalesSummary reportData={ reportData }/>
            <NetSalesList reportData={ reportData }/>
            <SalesChannel reportData={ reportData }/>
            <SalesCategoryList reportData={ reportData }/>
            <DayPartSummary reportData={ reportData }/>
            <PaymentsList list={ reportData.paymentStatsList }/>
            <ServiceChargeList list={ reportData.serviceChargeStatsList }/>
            <Row gutter={isMobile ? [0, 8] : [16, 16]} className='flex-box'>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <InfoCard infoData={ reportData.taxList } title={ 'Tax by Tax ID' } setInfoKey={{ title: 'taxName', key: 'taxUuid', content: 'taxAmount' }}/>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <InfoCard infoData={ reportData.diningOptionGuestCountList } title={ 'Guest Count by Order Mode' } setInfoKey={{ title: 'diningOptionName', key: 'diningOptionId', content: 'guestCount' }}/>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <InfoCard infoData={ reportData.dayPartSummaryDTO?.dayPartCheckCountList } title={ i18n.t('Check_Count_by_DayPart') } setInfoKey={{ title: 'dayPartName', content: 'guestCount' }}/>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <InfoCard infoData={ reportData.dayPartSummaryDTO?.dayPartGuestCountList } title={ i18n.t('Guest_Count_by_DayPart') } setInfoKey={{ title: 'dayPartName', content: 'guestCount' }}/>
              </Col>
            </Row>
            <SalesByDayPart salesCategorySummaryDTO={reportData.dayPartSummaryDTO?.salesCategorySummaryDTO} />
            <div className='row-box'>
              <Comps infoData={ reportData.promotionSummaryList } title={ 'Comps' } setInfoKey={{ title: 'promotionName', info: 'promotionCount', key: 'promotionUuid', content: 'promotionAmount' }}/>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}