import React, { Component } from 'react'
import { Tabs } from 'antd'
import { formatBackI18n } from '@/utils'
import './index.scss'
interface IProps {
  title: string
  items?: any[]
  value?: string
  rightContent?: React.ReactNode
  onChange?: (key: string) => void
  defaultActiveKey?: string
}
export default function HeaderTitle (props: IProps) {
  const { title = '', rightContent = null, items = [], onChange = () => {}, defaultActiveKey = null, value } = props
  return (
    <div id='header-title'>
      <Tabs
        id='header-tabs'
        activeKey={value}
        defaultActiveKey={ defaultActiveKey }
        className={`${items.length > 1 ? 'more-tabs' : ''}`}
        tabBarExtraContent={{
          left:<div className="title">{ formatBackI18n(title) }</div>,
          right: items.length < 1 ? rightContent : null
        }}
        onChange={ onChange }
        items={ items }
      ></Tabs>
      {items.length > 1 && rightContent ? <div className="btn-wrap">{ rightContent }</div> : null}
    </div>
  )
}