import React, { useEffect, useRef, useState } from 'react';
import { useBus } from 'react-bus';
import { Layout, Menu, Popover, MenuProps, Flex, Space } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MailOutlined,
  PhoneOutlined,
  CommentOutlined,
  ExportOutlined,
  MenuOutlined,
  CloseOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import './index.scss';
import Logo from './Logo';

// @assets
import '@/assets/MenuIcon/icon.js';
// @request
import { ApiLogout } from '@/request/Login/index';
import { getLoginInfo, switchOrg } from '@/request/Login/index';
import { ApiGetEmployeeEntranceTree } from '@/request/api';

// @stores
import { globalState } from '@/stores';
import store from '@/stores/userInfo';
import { useMobile } from '@/stores/MobileContext';
import WhiteList from '@/stores/whiteList';
// @utils
import { parseHash, debounce } from '@/utils';
import GlobalDrawer from '@/utils/GlobalDrawer';
import {
  _i18n,
  setCurrentLang,
  getCurrentLang,
  getSupportLangs,
} from '@/utils/language';
// @router
import { routes } from '@/router/routes';
import { useNavigate, Outlet } from 'react-router-dom';

import UserActions from './UserActions';
const { Header, Sider, Content } = Layout;
let employeeData = null;
let empInfoData = null;

type MenuItem = Required<MenuProps | any>['items'][number];

type Language = {
  code: string;
  locale: string;
  displayName: string;
  lang: string;
};

function getItem (
  label: string,
  key: string,
  id?: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    id,
    children,
    label,
    type,
    theme: 'light',
  } as MenuItem;
}
// @withRouter
export default function LayoutCom (props: any) {
  const i18n = globalState.i18n;

  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [employeeMenus, setEmployeeMenus] = useState<any>(employeeData);
  const [curOrgInfo, setCurOrgInfo] = useState<any>({});
  const [orgList, setOrgList] = useState<any>([]);
  const [path, setPath] = useState<string>(window.location.pathname);
  const [languageList, setLanguageList] = useState<Language[]>([]);
  const [currentLanguage, setCurrentLanguageItem] = useState<any>({});
  const [isLanguage, setIsLanguage] = useState<boolean>(true);

  const [PageList, setPageList] = useState<any>();
  const bus = useBus();
  const employeeMenusRes = [];
  const { isMobile } = useMobile();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [storeOpenKeys, setStoreOpenKeys] = useState<string[]>([]);

  const toggle = () => {
    setCollapsed(!collapsed);
    setOpenKeys(storeOpenKeys);
    goLink({ item: '', key: path, keyPath: '', domEvent: '' });
  };

  const handleMenu = (key, pageList) => {
    if (!pageList) return [];
    const _data = JSON.parse(JSON.stringify(pageList));
    const menuList: MenuItem[] = [];

    const findOrCreateParent = (parentId, label, id, icon) => {
      let parent = menuList.find((x) => x.id === parentId);
      if (!parent) {
        parent = getItem(label, id, id, icon, []);
        menuList.push(parent);
      } else if (!parent.children) {
        parent.children = [];
      }
      return parent;
    };

    _data.forEach((_menuItem: any) => {
      const key = _menuItem.url;
      const label = globalState.i18n?.t(_menuItem.name) ?? '';
      const id = _menuItem.id;
      const icon = (
        <i
          className={classNames(
            'iconfont',
            key === _menuItem.url
              ? _menuItem.icon || 'icon-home' + ' fill'
              : _menuItem.icon ?? 'icon-home'
          )}
          aria-hidden="true"
        ></i>
      );

      if (!_menuItem.parentId) {
        menuList.push(getItem(label, key, id, icon));
      } else {
        const parent = findOrCreateParent(_menuItem.parentId, label, id, icon);
        parent.children.push(getItem(label, key, id));
      }
    });
    return menuList;
  };

  const goLink = (options: {
    item: string;
    key: string;
    keyPath: string;
    domEvent: string;
  }) => {
    setPath(options.key);
    navigate(options.key);
    if (!!options.key && !!PageList) {
      setEmployeeMenus(handleMenu(options.key, PageList));
    }
  };


  const handleSubMenuClick = (e) => {
    const keyPath = e.keyPath.reverse();
    setOpenKeys([keyPath[0]]);
    setStoreOpenKeys([keyPath[0]])
    goLink(e);
  };

  const onOpenChange = (openKeys: string[]) => {
    setOpenKeys([openKeys[openKeys.length - 1]]);
  };


  const goToMyAccount = () => {
  // 跳转至账号页面
    navigate('/myAccount');
  };

  const goToLogout = () => {
  // 退出登录
    employeeData = null;
    empInfoData = null;
    ApiLogout().then((res) => {
      if (res) {
        navigate('/login')
        sessionStorage.removeItem('accId');
        sessionStorage.removeItem('tenantId');
        sessionStorage.removeItem('loginToken');
        sessionStorage.removeItem('nodeId');
        // localStorage.removeItem('userInfo');
        localStorage.removeItem('shopInfo');
        store.clearUserInfo();
        store.clearShopInfo();
      }
    });
  };

  const queryShopInfo = async (orgId: any, tenantId: any) => {
    const res = await switchOrg({
      tenantId,
      orgId,
    });
    if (res) {
      sessionStorage.setItem('tenantId', tenantId);
      sessionStorage.setItem('nodeId', orgId);
      res && window.location.assign(window.location.origin);
    }
  };

  const setLanguage = (code: any) => {
    setCurrentLanguageItem(code);
    setCurrentLang(code.locale);
    setIsLanguage(true);
    setTimeout(() => {
      document.location.reload();
    }, 300);
  };
  const findRouteItem = (path) => {
    for (const route of routes) {
      if (route.path === path) return route;
      if (route.children) {
        for (const child of route.children) {
          if (child.path === path) return child;
        }
      }
    }
    return null;
  };
  const handleRouteChangeDrawerControl = () => {
    const hash = window.location.hash;
    if (!hash && GlobalDrawer.drawerContainer) {
      GlobalDrawer.close();
      return;
    }
    const { route, params } = parseHash(hash);
    const matchRoute = findRouteItem(route);
    // routers.ts 文件
    // const matchRoutes = routers.find(x => x.url === route);
    if (!matchRoute) return;
    GlobalDrawer.open({
      component: matchRoute.element,
      props: { ...params, bus },
      navigate,
    });
  };
  const flattenMenus = (arr) => {
    (Array.isArray(arr) ? arr : []).forEach((item) => {
      employeeMenusRes.push(item);
      if (item.subEntrances && item.subEntrances.length > 0) {
        flattenMenus(item.subEntrances);
      }
    });
  };
  const debounceGetUserInfo = debounce(async () => {
    if ((!employeeData && !empInfoData) || isLanguage) {
    // 用户点击刷新时候进行重新获取数据
      setIsLanguage(false);
      globalState.i18n = _i18n;
      ApiGetEmployeeEntranceTree().then((res) => {
        if (res.code === 0) {
          const { entrances = [], permissionIds = [] } = res.data
          localStorage.setItem('permissionIds', JSON.stringify(permissionIds))
          store.setPermissionIds(permissionIds);
          if (entrances.length > 0) {
            employeeMenusRes.length = 0;
            flattenMenus(entrances);
            store.setEmployeeMenusRes(employeeMenusRes);
            const _data = JSON.parse(JSON.stringify(employeeMenusRes));
            setEmployeeMenus(handleMenu(path, employeeMenusRes));
            setPageList(_data);
            localStorage.setItem('employeeMenusRes', JSON.stringify(_data));
            employeeData = handleMenu(path, employeeMenusRes);
          }
        }
      });
      const res = await getLoginInfo();
      // 处理res的逻辑
      if (res) {
        const { curOrgInfo, empInfo, orgList } = res;
        const { timeZone } = curOrgInfo;
        const shopInfo = {
          timeZoneId: timeZone ?? 'America/Los_Angeles',
          createdTime: empInfo?.createTime,
          name: curOrgInfo?.orgName,
          modifiedTime: empInfo?.modifiedTime,
          nodeId: curOrgInfo?.orgId,
          ...curOrgInfo,
        };
        setCurOrgInfo(shopInfo);
        setOrgList(orgList);
        empInfoData = empInfo;
        store.setUserInfo(empInfo);
        localStorage.setItem('shopInfo', JSON.stringify(shopInfo));
        store.setShopInfo(shopInfo);
      }
    }
  }, 500);
  // @ts-expect-error ignore
  const intervalRef = useRef<number>();
  const numRef = useRef<number>(0);

  useEffect(() => {
    WhiteList.fetchWhiteList();
    intervalRef.current = setInterval(() => {
      if (numRef.current >= 20) {
        clearInterval(intervalRef.current);
      }
      const settings = localStorage.getItem('i18n/supportLangs');
      numRef.current++;
      if (!!settings) {
        clearInterval(intervalRef.current);
        globalState.i18n = _i18n;
      }
    }, 100) as unknown as number;
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    debounceGetUserInfo();
    if (window.innerWidth < 768) {
      setCollapsed(true);
    }
  }, [path, isLanguage]);

  useEffect(() => {
    dealWithLanguage();
  }, [window.location.pathname, curOrgInfo]);

  const dealWithLanguage = async () => {
    setPath(window.location.pathname);
    // 加载语言包
    const languageList = await getSupportLangs();
    const useLangs = curOrgInfo.useLangs || [];
    const updatedLanguageList = useLangs.map((langObj) => {
      const matchedLang = languageList?.find(
        (item) => item.locale === langObj.lang
      );
      return matchedLang ? { ...langObj, ...matchedLang } : langObj;
    });

    // 设置语言列表
    setLanguageList(updatedLanguageList);
    setCurrentLanguageItem(
      updatedLanguageList.find((x) => x.locale === getCurrentLang())
    );

    if (getCurrentLang()) {
      globalState.i18n = _i18n;
    }
  };
  const sendEmail = () => {
    const recipient = _i18n.t('dashboard_support_email');
    const subject = 'help';
    const body = 'input your question';
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
      recipient
    )}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(gmailUrl, '_blank');
  };



  useEffect(() => {
    handleRouteChangeDrawerControl();
  }, [window.location.hash]);


  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        collapsedWidth={isMobile ? 0 : 60}
        width={isMobile ? 200 : 230}
      >
        {!isMobile && (
          <Logo
            collapsed={collapsed}
            onClick={() => {
              navigate('/');
            }}
          />
        )}
        <Menu
          className="snv-menu"
          mode="inline"
          style={{ fontSize: 14, maxHeight: isMobile ? '98vh' : '85vh' }}
          defaultSelectedKeys={['/']}
          selectedKeys={[path]}
          theme="dark"
          items={employeeMenus}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onSelect={handleSubMenuClick}
        />
        {!isMobile && (
          <div className="MenuUnfoldOutlined" onClick={toggle}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        )}
      </Sider>
      <Layout className="cardContent">
        <Header className="header">
          {isMobile && (
            <div style={{ display: 'flex' }}>
              <Logo
                collapsed={true}
                onClick={() => {
                  navigate('/');
                }}
              />
              <div className="menuUnfoldOutBtn" onClick={toggle}>
                {collapsed ? <MenuOutlined /> : <CloseOutlined />}
              </div>
            </div>
          )}
          <Flex justify="space-between" align="center">
            {orgList?.length > 1 && !isMobile ? (
              <Popover
                content={
                  <div className="menuList">
                    {orgList?.map((x) => {
                      return (
                        <div
                          className="menuListBox"
                          key={x.orgId}
                          onClick={() => {
                            queryShopInfo(x.orgId, x.tenantId);
                          }}
                        >
                          {x.orgName}
                        </div>
                      );
                    })}
                  </div>
                }
                placement="bottomLeft"
                trigger="click"
                arrow={false}
              >
                <div
                  style={{ margin: '0 4px', cursor: 'pointer' }}
                  className="home-name"
                >
                  {curOrgInfo?.orgName}{' '}
                  <i className="iconfont icon-DownOutlined"></i>
                </div>
              </Popover>
            ) : (
              <span
                style={{
                  margin: '0 4px',
                  display: orgList?.length <= 1 ? 'block' : 'none',
                  maxWidth: '200px'
                }}
                className="home-name"
              >
                {curOrgInfo?.orgName}
              </span>
            )}
            <Flex className="header-userName" align="center">
              <Popover
                content={
                  <Space direction="vertical" size={8} className="help-box">
                    <div className="title">
                      {_i18n.t('dashboard_support_resources')}
                    </div>
                    <div className="info" style={{ marginBottom: '16px' }}>
                      <a
                        href="https://help.peppr.com/userguide/?l=en"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="help-center"
                      >
                        <span>{_i18n.t('dashboard_support_center')}</span>
                        <ExportOutlined />
                      </a>
                    </div>
                    <div className="title">
                      {_i18n.t('dashboard_support_contact')}
                    </div>
                    <div className="info">
                      <a onClick={sendEmail}>
                        <MailOutlined />
                        {_i18n.t('dashboard_support_email')}
                      </a>
                    </div>
                    <div className="info">
                      <CommentOutlined />
                      {_i18n.t('dashboard_support_text')}
                    </div>
                    <div className="info">
                      <a
                        href={`tel:${_i18n.t('dashboard_support_phone')}`}
                        style={{ color: '#313131' }}
                      >
                        <PhoneOutlined />
                        {_i18n.t('dashboard_support_phone')}
                      </a>
                    </div>
                  </Space>
                }
                placement="bottomRight"
                trigger="click"
                arrow={false}
              >
                {isMobile ? (
                  <QuestionOutlined style={{ fontSize: '24px' }} />
                ) : (
                  <div className="select-btn">
                    {_i18n.t('dashboard_help')}{' '}
                    <i
                      className="iconfont icon-DownOutlined"
                      style={{ marginLeft: '16px' }}
                    ></i>
                  </div>
                )}
              </Popover>
              {!isMobile && languageList.length > 1 && (
                <Popover
                  content={
                    <div>
                      {languageList.length &&
                        languageList?.map((x, i) => {
                          return (
                            <div
                              className="menuListBox"
                              key={i}
                              onClick={(e) => {
                                e.stopPropagation();
                                setLanguage(x);
                              }}
                            >
                              {i18n?.t(x.displayName)}
                            </div>
                          );
                        })}
                    </div>
                  }
                  placement="bottomRight"
                  trigger="click"
                  arrow={false}
                >
                  <div
                    className="select-btn"
                    style={{ margin: '0 12px 0 24px' }}
                  >
                    <span
                      className="iconfont icon-GlobalOutlined"
                      style={{
                        marginRight: 5,
                      }}
                    ></span>
                    <div>{i18n?.t(currentLanguage?.displayName)}</div>
                    <i
                      className="iconfont icon-DownOutlined"
                      style={{ marginLeft: '16px' }}
                    ></i>
                  </div>
                </Popover>
              )}
              <UserActions
                onMyAccount={goToMyAccount}
                onLogout={goToLogout}
                i18n={globalState.i18n}
              />
            </Flex>
          </Flex>
        </Header>
        {isMobile && !collapsed ? (
          <div className="bg-none" onClick={toggle}></div>
        ) : null}
        <Content>
          {!!globalState.i18n && <Outlet />}
        </Content>
      </Layout>
    </Layout>
  );
}
