import React, { useEffect, useState } from 'react';
import { Button, InputNumber } from 'antd';
import './OrderPriceRules.scss';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { globalState } from '@/stores';

interface OrderPriceRulesProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  onValidationChange?: (hasError: boolean, hasWarning: boolean) => void;
}

interface Rule {
  id: number;
  dollarAmount: number;
  hour: number;
  mins: number;
}

const { i18n } = globalState;

{/*
// 包含红色星号的输入框组件
const InputWithPlaceholder: React.FC<{
  value: number | null;
  onChange: (value: number | null) => void;
  placeholder: string;
  required?: boolean;
  style?: React.CSSProperties;
  onBlur?: (value: number | null) => void;
  disabled?: boolean;
}> = ({ value, onChange, placeholder, required = false, style, onBlur, disabled }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="input-with-placeholder" style={style}>
      <Input
        className="order-price-input"
        value={value !== null ? String(value) : ''}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          if (onBlur) onBlur(value);
        }}
        onChange={(e) => {
          const inputValue = e.target.value;

          // 只允许数字或空值
          if (inputValue === '') {
            onChange(null);
          } else if (/^\d+$/.test(inputValue)) { // 仅允许整数
            onChange(Number(inputValue));
          }
        }}
        style={style}
        disabled={disabled}
      />
      <span className={`input-placeholder ${value !== null || isFocused ? 'hidden' : ''}`}>
        {placeholder}
        {required && <span className="required-star">*</span>}
      </span>
    </div>
  );
};
*/}

// 行组件，负责渲染每一行的内容
const OrderPriceRow: React.FC<{
  onDelete?: () => void;
  isDeletable?: boolean;
  onChange: (field: keyof Rule, value: number | null) => void;
  values: Rule;
  onBlur?: () => void;
  disabled?: boolean;
}> = ({ onDelete, isDeletable = false, onChange, values, onBlur, disabled }) => {
  return (
    <div className="order-price-row">
      <span className="order-price-label">{ i18n.t('PC_OO_Quotetime_Rules_Description_Head') || 'If an order (subtotal) is over'}</span>
      <InputNumber
        addonAfter={ i18n.t('PC_OO_Quotetime_Rules_Amount') || 'Dollar Amount'} min={ 1 } max={ 1000000000 } precision={ 0 }
        style={{ width: 400 }}
        disabled={ disabled }
        value={values.dollarAmount}
        onChange={(value) => onChange('dollarAmount', value)}
        onBlur={onBlur}
      />
      <span className="order-price-label">{ i18n.t('PC_OO_Quotetime_Rules_Increase') || 'increase'}</span>
      <InputNumber
        addonAfter={ i18n.t('PC_OO_Quotetime_Rules_Hour') || 'Hour'} min={ 0 } max={ 24 } precision={ 0 }
        disabled={ disabled }
        value={values.hour}
        onChange={(value) => onChange('hour', value)}
        onBlur={onBlur}
      />
      <InputNumber
        addonAfter={ i18n.t('pc_online_orders_min') || 'Min'} min={ 0 } max={ 60 } precision={ 0 }
        disabled={ disabled }
        value={values.mins}
        onChange={(value) => onChange('mins', value)}
        onBlur={onBlur}
      />
      <span className="order-price-label">{ i18n.t('PC_OO_Quotetime_Rules_Description_Tail') || 'to takeout time'}</span>
      {isDeletable && (
        <CloseCircleOutlined
          style={{
            color: disabled ? 'gray' : '#2563EB',
            cursor: disabled ? 'not-allowed' : 'pointer',
            marginLeft: '8px',
          }}
          onClick={!disabled ? onDelete : undefined}
        />

      )}
    </div>
  );
};

// 按钮组件
const OrderPriceButton: React.FC<{
  currentCount: number;
  onAddRow: () => void;
  maxRows: number;
  disabled?: boolean;
}> = ({ currentCount, onAddRow, maxRows, disabled = false }) => (
  <Button
    disabled={disabled || currentCount >= maxRows}
    onClick={onAddRow}
    type="link"
    style={{
      pointerEvents: disabled ? 'none' : 'auto',
      opacity: disabled ? 0.5 : 1,
    }}
  >
    <PlusOutlined />
    { i18n.t('PC_OO_Quotetime_Rules_Add_Rules') || 'Additional rules'}
  </Button>
);


const OrderPriceRules: React.FC<OrderPriceRulesProps> = ({ value, onChange, disabled, onValidationChange }) => {
  // 解析外部 value 到 rows 结构
  const parseValueToRows = (value: string): Rule[] => {
    try {
      const parsed = JSON.parse(value || '[]');
      if (Array.isArray(parsed) && parsed.length > 0) {
        return parsed.map((row, index) => ({
          id: row.id ?? index,
          dollarAmount: row.dollarAmount ?? null,
          hour: row.hour ?? null,
          mins: row.mins ?? null,
        }));
      }
    } catch (e) {
      console.error('Failed to parse value:', e);
    }
    // 默认初始行
    return [];
  };

  // 使用 value 初始化 rows
  const [rows, setRows] = useState<Rule[]>(() => parseValueToRows(value));
  const [error, setError] = useState(false); // 新增 error 状态
  const [rowErrors, setRowErrors] = useState<Record<number, boolean>>({}); // 每行的错误状态
  const [rowTouched, setRowTouched] = useState<Record<number, boolean>>({}); // 每行的交互状态
  const [showWarning, setShowWarning] = useState(false); // showWarning 判断低金额高时间
  const [hasError, setHasError] = useState(false); // 判断是否有error

  const validateRules = (rules) => {
    try {
      const parsed = JSON.parse(rules || '[]');

      // 检查是否有效数组
      if (!Array.isArray(parsed)) {
        setHasError(true);
        return;
      }

      // 检查每个字段
      const hasRequiredError = parsed.some(rule =>
        !rule.dollarAmount ||
      rule.dollarAmount <= 0 ||
      (rule.hour === null && rule.mins === null) ||
      (rule.hour <= 0 && rule.mins <= 0)
      );

      // 价格时间顺序冲突检查
      const hasWarning = validatePriceTimeOrder(parsed);

      // 同步双重状态到父组件
      if (onValidationChange) {
        onValidationChange(hasRequiredError, hasWarning);
      }

      // 本地状态更新
      setHasError(hasRequiredError);
      setShowWarning(hasWarning);
    } catch (e) {
      setHasError(true);
      setShowWarning(false);
      if (onValidationChange) onValidationChange(true, false);
    }
  }


  useEffect(() => {
    validateRules(value);
  }, [value]);

  // 将 hour 和 mins 转换为总分钟数
  const calculateTotalMinutes = (hour: number | null, mins: number | null) => {
    return (hour || 0) * 60 + (mins || 0);
  };

  // 校验是否存在低金额却高时间的规则
  const validatePriceTimeOrder = (
    rows: Rule[]
  ) => {
    // 只筛选出完整填写的有效规则行（允许hour或mins为0）
    const validRows = rows.filter(row =>
      row.dollarAmount !== null &&
      row.dollarAmount > 0 && // 金额必须大于0
      (row.hour !== null || row.mins !== null) // 时间至少填一项
    );

    // 转换为可比较的结构
    const mappedRows = validRows.map(row => ({
      dollar: row.dollarAmount!,
      minutes: calculateTotalMinutes(row.hour, row.mins)
    }));

    // 检查所有规则对
    for (let i = 0; i < mappedRows.length; i++) {
      for (let j = i + 1; j < mappedRows.length; j++) {
        const a = mappedRows[i]
        const b = mappedRows[j]

        // 等值校验
        if (a.dollar === b.dollar && a.minutes === b.minutes) return true;
        if (a.dollar === b.dollar) return true;
        if (a.minutes === b.minutes) return true;


        // 双向比较：如果存在价格低但时间长的组合
        if (
          (a.dollar < b.dollar && a.minutes > b.minutes) ||
          (a.dollar > b.dollar && a.minutes < b.minutes)
        ) {
          return true
        }
      }
    }
    return false
  }


  // 校验单行数据
  const validateRow = (row: Rule) => {
    const { dollarAmount, hour, mins } = row;
    // 如果所有字段都为空，则认为该行有效
    if (dollarAmount === null && hour === null && mins === null) return false;
    // 金额必须大于 0，时间中至少一个值大于 0
    if (dollarAmount === null || dollarAmount <= 0) return true;
    if ((hour === null || hour <= 0) && (mins === null || mins <= 0)) return true;
    return false;
  };

  // 校验所有行数据
  const validateAllRows = (rows: Rule[]) => {
    // 1. 计算必填错误
    const rawErrors = rows.map(row => validateRow(row));
    const hasRequiredError = rawErrors.some(Boolean);

    // 2. 仅在必填错误状态变化时更新
    setError(prev => hasRequiredError !== prev ? hasRequiredError : prev);

    // 3. 计算逻辑冲突（仅在无必填错误时计算）
    let hasPriceTimeError = false;
    if (!hasRequiredError) {
      hasPriceTimeError = validatePriceTimeOrder(rows);
      setShowWarning(prev => hasPriceTimeError !== prev ? hasPriceTimeError : prev);
    } else {
      setShowWarning(false); // 存在必填错误时强制关闭警告
    }

    // 4. 更新行级错误状态
    const rowErrorsMap: Record<number, boolean> = {};
    rows.forEach((row, index) => {
      rowErrorsMap[row.id] = rowTouched[row.id] ? rawErrors[index] : false;
    });
    setRowErrors(rowErrorsMap);

    return hasRequiredError || hasPriceTimeError;
  };

  // 当外部 value 变化时（如表单重置），同步到内部状态
  useEffect(() => {
    const newRows = parseValueToRows(value);
    setRows(newRows);
    validateAllRows(newRows); // 同步校验
  }, [value]);

  // 当内部 rows 变化时，同步到外部 onChange
  useEffect(() => {
    const valueString = JSON.stringify(rows);
    onChange(valueString);
    validateAllRows(rows); // 仅调用校验函数，不设置 error
  }, [rows]);

  // 处理行数据更新
  const handleUpdateRow = (id: number, field: keyof Rule, value: number | null) => {
    const newRows = rows.map(row =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setRows(newRows);
    setRowTouched({ ...rowTouched, [id]: true }); // 标记该行已交互

    // 动态校验当前行
    const currentRow = newRows.find(row => row.id === id);
    if (currentRow) {
      const isError = validateRow(currentRow);
      setRowErrors({ ...rowErrors, [id]: isError }); // 更新当前行的错误状态
    }

    // 校验所有行并更新全局错误状态
    validateAllRows(newRows);

    // 传递总分钟数
    const updatedRows = newRows.map(row => ({
      ...row,
      totalMinutes: calculateTotalMinutes(row.hour, row.mins),
    }));
    onChange(JSON.stringify(updatedRows));
  };

  // 处理删除行
  const handleDeleteRow = (id: number) => {
    const newRows = rows.filter(row => row.id !== id);
    setRows(newRows);

    // 强制重置错误和警告状态
    setError(false);
    setShowWarning(false);

    const { [id]: _, ...newRowTouched } = rowTouched;
    setRowTouched(newRowTouched);
  };

  // 处理添加行
  const handleAddRow = () => {
    const hasEmptyRow = rows.some(row =>
      row.dollarAmount === null && row.hour === null && row.mins === null
    );
    if (error || showWarning || hasEmptyRow) {
      return;
    }
    const newId = rows.reduce((maxId, row) => Math.max(maxId, row.id), 0) + 1;
    setRows([...rows, { id: newId, dollarAmount: null, hour: null, mins: null }]);
    setRowTouched({ ...rowTouched, [newId]: false });
  };

  // 新增 handleBlur 方法
  const handleBlur = () => {
  // 仅在当前存在错误或警告时触发全量校验
    if (error || showWarning) {
      validateAllRows(rows);
    }
  };

  return (
    <div className="order-price-input-wrap">
      <OrderPriceButton
        currentCount={rows.length}
        onAddRow={handleAddRow}
        maxRows={5}
        disabled={disabled}
      />
      <div className="top-container">
        <div className="order-price-warnings" style={{ position: 'relative', height: '24px' }}>
          {/* 当有规则存在时显示提示 */}
          {rows.length > 0 && (
            <>
              {/* 默认提示（无状态时显示） */}
              <div className={`default-order-price ${
                !error && !showWarning && rows.length > 0 ? 'slide-in' : 'slide-out'
              }`}>
                { i18n.t('PC_OO_Quotetime_Rules_Warn_Empty') || 'Please add a price and time to add order price rule.'}
              </div>

              {/* 错误提示 */}
              <div className={`error-order-price ${error ? 'slide-in' : 'slide-out'}`}>
                { i18n.t('PC_OO_Quotetime_Rules_Warn_Empty') || 'Please add a price and time to add order price rule.'}
              </div>

              {/* 警告提示 */}
              <div className={`warning-order-price ${showWarning ? 'slide-in' : 'slide-out'}`}>
                { i18n.t('PC_OO_Quotetime_Rules_Warn_Error') || 'The rule with the higher price must have a greater time than the lower price rule.'}
              </div>
            </>
          )}
        </div>
      </div>



      {/* 规则行容器 */}
      <div className="order-price-container">
        {rows.map((row) => (
          <div className="rule-row-container" key={row.id}>
            <OrderPriceRow
              isDeletable={true}
              onDelete={() => handleDeleteRow(row.id)}
              onChange={(field, value) => handleUpdateRow(row.id, field, value)}
              values={row}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderPriceRules;
