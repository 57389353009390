import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Row, Col, Spin } from 'antd';
import SummaryCard from '@/Components/SummaryCard';
import './index.scss';
import { TableToolbar } from '@/Components/TableToolbar';
import type { FilterItem } from '@/Components/FilterContainer/types';
import { ApiQueryGiftCardSummary } from '@/request/api';
import { useTime } from '@/hooks/useTime';
import useRequest from '@/hooks/useRequest';
import { globalState } from '@/stores';

interface GiftCardSummary {
  totalSales: Partial<{
    totalSales: string;
    addedValue: string;
    voidedAddValue: string;
    adjustBalance: string;
  }>;
  totalRedemptions?: Partial<{
    totalRedemptions: string;
    redemptions: string;
    voidedRedemptions: string;
  }>;
  cardCount?: Partial<{
    activeCardCount: string;
    inActiveCount: string;
  }>;
  netLiabilityChange?: Partial<{
    netLiabilityChange: string;
    startLiability: string;
    endLiability: string;
  }>;
}

const Summary: React.FC = () => {
  const { i18n } = globalState
  const { today, lastSevenDays, lastSevenDayTime, updateDateRange } = useTime();
  const printRef = useRef(null);
  const { data, loading, refresh } = useRequest<GiftCardSummary>(() => {
    if (lastSevenDayTime.length > 0) {
      return ApiQueryGiftCardSummary({
        beginTime: lastSevenDayTime[0],
        endTime: lastSevenDayTime[1]
      })
    }
  }, {
    deps: [lastSevenDayTime],
  });

  const initialFilterValues = React.useMemo(() => ({
    dateRange: lastSevenDays
  }), [lastSevenDays]);


  const filters: FilterItem[] = React.useMemo(()=>[
    {
      name: 'dateRange',
      type: 'dateRange',
      today
    },
  ], [today]);

  const summaryCards = useMemo(() => {
    const { totalSales, totalRedemptions, cardCount, netLiabilityChange } = data || {};
    return [
      {
        title: i18n.t('pc_product_mix_total_sales'),
        value: totalSales?.totalSales,
        subItems: [
          { label: i18n.t('dashboard_report_added_value'), value: totalSales?.addedValue },
          { label: i18n.t('dashboard_report_voided_add_value'), value: totalSales?.voidedAddValue },
          { label: i18n.t('dashboard_report_adjust_balance'), value: totalSales?.adjustBalance },
        ]
      },
      {
        title: i18n.t('dashboard_report_total_redemptions'),
        value: totalRedemptions?.totalRedemptions,
        subItems: [
          { label: i18n.t('dashboard_report_redemptions'), value: totalRedemptions?.redemptions },
          { label: i18n.t('dashboard_report_voided_redemptions'), value: totalRedemptions?.voidedRedemptions },
        ]
      },
      {
        title: i18n.t('dashboard_report_card_count'),
        isCount: true,
        subItems: [
          { label: i18n.t('dashboard_report_active_card'), value: cardCount?.activeCardCount, type: 'isCount' },
          { label: i18n.t('dashboard_report_inactive_card'), value: cardCount?.inActiveCount, type: 'isCount' },
        ]
      },
      {
        title: i18n.t('dashboard_report_net_liability_change'),
        value: netLiabilityChange?.netLiabilityChange,
        subItems: [
          { label: i18n.t('dashboard_report_starting_liability'), value: netLiabilityChange?.startLiability, showPlus: true },
          { label: i18n.t('dashboard_report_ending_liability'), value: netLiabilityChange?.endLiability },
        ]
      },
    ]
  }, [data]);

  return (
    <div className="gift-card-summary" ref={printRef}>
      <Spin size="large" spinning={ loading }>
        <TableToolbar
          filters={filters}
          filterValue={initialFilterValues}
          printRef={printRef}
          onFilterChange={updateDateRange}
          onRefresh={refresh}
        />
        <Row gutter={[16, 16]}>
          {summaryCards.map((card, index) => (
            <Col key={index} xs={24} sm={24} md={12}>
              <SummaryCard {...card} />
            </Col>
          ))}
        </Row>
      </Spin>
    </div>
  );
};

export default Summary;
