import React, { useRef, useState } from 'react'
import { Tabs } from 'antd'
import LaborSummary from '../LaborSummary'
import EmployeeCheckoutReport from '../EmployeeCheckoutReport'
import EmployeeTimeSheets from '../EmployeeTimeSheets'
import { globalState } from '@/stores'
import './index.scss'
import HeaderTitle from '@/Components/HeaderTitle'

const LaborReport = () => {
  const { i18n } = globalState
  const [tabKey, setTabKey] = useState('1')

  const items  = [
    {
      label: i18n?.t('overseas_peppr_entrance_laborsummary'),
      key: '1',
      children: <LaborSummary  />,
    },
    {
      label: i18n.t('dashboard_checkout_report'),
      key: '2',
      children: <EmployeeCheckoutReport />,
    },
    {
      label: i18n.t('overseas_peppr_entrance_employeeTimeSheets'),
      key: '3',
      children: <EmployeeTimeSheets />,
    },
  ]

  return (
    <div className="labor-report">
      <HeaderTitle
        title={ i18n.t('overseas_peppr_entrance_labor') }
        items={ items }
        defaultActiveKey={tabKey}
        onChange={ val => setTabKey(val) } />
    </div>
  )
}
export default LaborReport