import React, { useState, useEffect } from 'react';
import { CalendarPickerView, Popup, Button, ConfigProvider } from 'antd-mobile';
import { CloseOutline, CalendarOutline } from 'antd-mobile-icons';
import enUS from 'antd-mobile/es/locales/en-US'
import dayjs from 'dayjs';
import { globalState } from '@/stores';
import './index.scss';
interface MobileDatePickerProps {
  value?: [dayjs.Dayjs, dayjs.Dayjs];
  presets?: { label: string; value: [dayjs.Dayjs, dayjs.Dayjs]; key: string }[];
  onChange?: (value: [dayjs.Dayjs, dayjs.Dayjs]) => void;
  onClose?: () => void;
  disabled?: boolean;
}

export const MobileDatePicker: React.FC<MobileDatePickerProps> = ({
  value,
  presets,
  onChange,
  onClose,
  disabled = false
}) => {
  const { i18n } = globalState;
  const [visible, setVisible] = useState(false);
  const [compValue, setCompValue] = useState<[Date, Date]>();

  const transformValue = (value: [dayjs.Dayjs, dayjs.Dayjs]) => {
    return value?.map(d => d?.toDate?.()) as [Date, Date];
  };

  const handleQuickSelect = (value) => {
    value && setCompValue(transformValue(value));
  };

  const handleConfirm = () => {
    if (onChange) {
      onChange([dayjs(compValue[0]), dayjs(compValue[1])]);
    }
    setVisible(false);
  };

  const handleClose = () => {
    setVisible(false);
    onClose?.();
    value && setCompValue(transformValue(value));
  };

  useEffect(() => {
    value && setCompValue(transformValue(value));
  }, [value]);

  return (
    <>
      <Button
        className='mobile-date-picker-button-view'
        onClick={() => setVisible(true)}
        disabled={disabled}
      >
        <div className='date-range-display'>
          <div className='date-range-content'>
            <span className='date'>
              {dayjs(compValue?.[0]).format('MM/DD/YYYY')}
            </span>
            <span className='separator'>
              <div className='line'></div>
            </span>
            <span className='date'>
              {dayjs(compValue?.[1]).format('MM/DD/YYYY')}
            </span>
          </div>
          <div className='calendar-icon'>
            <CalendarOutline />
          </div>
        </div>
      </Button>

      <Popup
        visible={visible}
        position='bottom'
        bodyStyle={{ height: '80vh' }}
      >
        <div className='mobile-date-picker'>
          <div className='mobile-date-picker-header'>
            <div className='mobile-date-picker-title'>
              <span>Select Date</span>
              <CloseOutline className='close-icon' onClick={handleClose} />
            </div>
            <div className='preset-scroll-container'>
              <div className='preset-scroll-wrapper'>
                {presets?.map((item) => (
                  <Button
                    className='preset-button'
                    size='small'
                    key={item.key}
                    onClick={() => handleQuickSelect(item.value)}
                  >
                    {item.label}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <ConfigProvider locale={enUS}>
            <CalendarPickerView
              value={compValue}
              selectionMode='range'
              onChange={(val) => setCompValue(val)}
            />
          </ConfigProvider>
          <div className='mobile-date-picker-footer'>
            <Button
              block
              color='primary'
              onClick={handleConfirm}
            >
              {i18n.t('pc_modal_confirm')}
            </Button>
          </div>
        </div>
      </Popup>
    </>
  );
};