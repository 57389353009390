import { Button, Table, message, Tooltip, Select } from 'antd'
import React, { useEffect, useState, useMemo } from 'react';
import { ApiExportPayoutReport, ApiQueryExportResult, ApiQueryPayoutSummary } from '@/request/api';
import { getToday, toRes } from '@/utils';
import { TableEmptyContent } from '@/Components/EmptyContent';
import { globalState } from '@/stores';
import dayjs, { Dayjs } from 'dayjs';
import { isAxiosError } from 'axios';
import useGetState from '@/hooks/useGetState';
import { useNavigate } from 'react-router-dom';
import './index.scss'
import { TableToolbar } from '@/Components/TableToolbar';
import type { FilterItem } from '@/Components/FilterContainer/types';
import HeaderTitle from '@/Components/HeaderTitle';
import { useMobile } from '@/stores/MobileContext';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface IParamsState {
  date: Dayjs[];
  queryType: 1 | 2;
}

interface ISummaryData {
  txn: number;
  paymentAmount: string;
  refund: string;
  chargeback: string;
  fees: string;
  netPayouts: string;
}

const EXPORT_API = '/api/pay-batch/export/exportPayoutReport'
interface ITableData {
  fees: string;
  salesPeriod: string;
  chargeback: string;
  txn: number;
  netPayouts: string;
  paymentAmount: string;
  payoutDate: string;
  refund: string;
  payoutBatch: string;
  status?: string;
}

export default function PayoutReport () {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const [today, setToday] = useState(dayjs())
  const [params, setParams] = useState<IParamsState>({
    date: [today.startOf('day'), today.endOf('day')],
    queryType: 1
  })
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [summaryData, setSummaryData] = useState<ISummaryData>({
    txn: 0, paymentAmount: '$0.00', refund: '$0.00', chargeback: '$0.00', fees: '$0.00', netPayouts: '$0.00'
  } as ISummaryData)
  const [pager, setPager] = useState({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [mouseInfo, setMouseInfo, getMouseInfo] = useGetState({ position: '', isMatch: false })

  const [isExporting, setIsExporting] = useState(false)
  const [intervalId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [activeTitle, setActiveTitle] = useState([])
  const [needShowStatus, setNeedShowStatus] = useState(false)

  let exportTaskId;

  const init = async ()=>{
    const _today = await getToday();
    setToday(_today);
    const sessionDate = sessionStorage.getItem('payoutReportDate') ? sessionStorage.getItem('payoutReportDate').split(',').map(x => dayjs(+x)) : null;
    sessionStorage.removeItem('payoutReportDate')
    const date = sessionDate ?? [_today.startOf('day'), _today.endOf('day')]
    await fetchTableData({ date });
    setParams((prev) => ({ ...prev, date }))
    return () => {
      stopExport();
    }
  }

  useEffect(() => {
    init()
    setActiveTitle(tableColumns.map(item => {
      // 检查 item 的类型,只有当 item 有 hide 属性时才进行判断
      if ('hide' in item && item.hide !== true) {
        return item.dataIndex
      }
      // 如果没有 hide 属性,直接返回 dataIndex
      if (!('hide' in item)) {
        return item.dataIndex
      }
      return undefined
    }).filter(Boolean))
  }, []);



  const fetchTableData = async ({ current, pageSize, date, newQueryType }: {
    current?: number,
    pageSize?: number,
    date?: Dayjs[],
    newQueryType?: 1 | 2
  } = {}) => {
    const _params = {
      request: {
        beginTime: (date ?? params.date)?.[0].format('YYYY-MM-DD 00:00:00'),
        endTime: (date ?? params.date)?.[1].format('YYYY-MM-DD 23:59:59'),
        queryType: newQueryType ?? params.queryType
      },
      current: current ?? pager.current,
      pageSize: pageSize ?? pager.pageSize
    }
    setLoading(true);
    const resp: any = await ApiQueryPayoutSummary(_params);
    const data = resp?.data;
    setLoading(false);
    if (resp?.code !== 0) {
      errorOutput(resp?.message, i18n.t('pc_payout_report_query_failed'))
      return;
    }
    stopExport();
    const resultList = data?.list ?? []
    setTableData(resultList)
    setNeedShowStatus(!!data?.needShowStatus)
    data?.summary && setSummaryData({ ...data.summary })
    setPager((prev) => ({
      ...prev,
      pageSize: pageSize ?? pager.pageSize,
      total: data?.total ?? 0,
      current: current ?? data?.current
    }))
  }

  const handleTableChange = (_pagination) => {
    fetchTableData({ current: _pagination.current, pageSize: _pagination.pageSize });
  }

  const errorOutput = (err, defaultOutput) => {
    if (isAxiosError(err) && [504, 408].includes(err.request.status)) {
      message.error(i18n.t('pc_timeout_exceeded'))
    } else {
      defaultOutput && message.error(defaultOutput)
    }
  }

  const handleTableRowClick = (record) => {
    return {
      onClick: () => {
        const _mouseInfo = getMouseInfo();
        if (_mouseInfo.isMatch) {
          sessionStorage.setItem('payoutReportDate', params.date.map(x => x.valueOf()).join(','))
          navigate(`/payments/payoutDetail?id=${ record.payoutBatch }`)
        }
      },
      onMouseDown: (event) => {
        setMouseInfo({ position: `${ event.clientX }+${ event.clientY }`, isMatch: false })
      },
      onMouseUp: (event) => {
        const _mouseInfo = getMouseInfo();
        if (_mouseInfo.position === `${ event.clientX }+${ event.clientY }`) {
          setMouseInfo({ ...mouseInfo, isMatch: true })
        }
      },
    }
  }

  // region 导出相关
  const handleExport = async () => {
    setIsExporting(true)
    const [err, data] = await toRes(ApiExportPayoutReport({
      params: {
        req: {
          beginTime: params.date[0].format('YYYY-MM-DD 00:00:00'),
          endTime: params.date[1].format('YYYY-MM-DD 23:59:59'),
          queryType: params.queryType
        }
      }
    }), { showErrMsg: false });
    exportTaskId = data?.exportTaskId ?? null;
    if (err || !exportTaskId) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'))
      setIsExporting(false)
      return;
    }
    getExportResult();
  }

  const getExportResult = async () => {
    const [err, data] = await toRes(ApiQueryExportResult({ params: { req: { exportTaskId } } }), { showErrMsg: false });
    if (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'))
      setIsExporting(false)
      stopExport();
      return;
    }
    if (data?.result && data?.url) {
      // 开启下载
      handleDownload(data.url);
      message.success(i18n.t('pc_payout_report_successfully_downloaded'))
      setIsExporting(false);
      exportTaskId = null;
    } else {
      const id = setTimeout(async () => {
        getExportResult();
      }, 2000);
      setTimeoutId(id);
    }
  }

  const stopExport = () => {
    if (intervalId !== null) {
      clearTimeout(intervalId);
      setTimeoutId(null);
    }
  }

  const onChangeColumns = (list) => {
    setActiveTitle(list)
  }

  const handleDownload = (url) => {
    if (!url) return;
    const urlObject = new URL(url);
    const filename = decodeURIComponent(urlObject.pathname.substring(urlObject.pathname.lastIndexOf('/') + 1));
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // endregion

  // region 时间选择器相关
  const handleChangeFilter = (values) => {
    setParams((prev) => ({ ...prev, ...values }))
    fetchTableData({ date: values?.date, newQueryType: values?.queryType, current: 1 });
  }

  const summaryList = [
    // { title: i18n.t('dashboard_payout_report_txn'), value: summaryData?.txn },
    { title: i18n.t('pc_payout_report_card_payment'), value: summaryData?.paymentAmount },
    { title: i18n.t('pc_payout_report_refunds'), value: summaryData?.refund },
    // { title: i18n.t('pc_payout_report_chargeback'), value: summaryData?.chargeback },
    { title: i18n.t('pc_payout_report_fees'), value: summaryData?.fees },
    { title: i18n.t('pc_payout_report_payout_total'), value: summaryData?.netPayouts },
  ]

  const baseColumns = [
    { dataIndex: 'payoutDate', key: 'payoutDate', title: i18n.t('pc_payout_report_payout_date'), width: 120, hide: false },
    { dataIndex: 'salesPeriod', key: 'salesPeriod', title: i18n.t('pc_payout_report_sales_period'), width: 300 },
    { dataIndex: 'txn', key: 'txn', title: '#Txn', width: 80 },
    { dataIndex: 'paymentAmount', key: 'paymentAmount', title: i18n.t('pc_payout_report_card_payment'), width: 150 },
    { dataIndex: 'refund', key: 'refund', title: i18n.t('pc_payout_report_refunds'), width: 100 },
    { dataIndex: 'chargeback', key: 'chargeback', title: i18n.t('pc_payout_report_chargeback'), width: 100 },
    { dataIndex: 'fees', key: 'fees', title: i18n.t('pc_payout_report_fees'), width: 80 },
    { dataIndex: 'netPayouts', key: 'netPayouts', title: i18n.t('pc_payout_report_payout_total'), width: 100 },
  ];

  const statusColumn = {
    dataIndex: 'status',
    key: 'status',
    title: () => (
      <div>
        {i18n.t('pc_payout_report_status')}
        <Tooltip title={i18n.t('pc_payout_report_status_notice')}>
          <QuestionCircleOutlined style={{ marginLeft: 4 }} />
        </Tooltip>
      </div>
    ),
    width: 100,
    render: (status: string) => {
      const statusStyle = {
        display: 'inline-block',
        padding: '2px 8px',
        borderRadius: '2px',
        fontSize: '12px',
        lineHeight: '16px',
      };

      const statusMap = {
        sent: {
          color: '#389E0D',
          background: '#F6FFED',
          border: '1px solid #B7EB8F',
          text: i18n.t('pc_payout_report_status_sent')
        },
        scheduled: {
          color: '#1890FF',
          background: '#E6F7FF',
          border: '1px solid #91D5FF',
          text: i18n.t('pc_payout_report_status_scheduled')
        }
      };

      const currentStatus = statusMap[status?.toLowerCase()] || {
        color: '#000000',
        background: '#F5F5F5',
        border: '1px solid #D9D9D9',
        text: status || '-'
      };

      return (
        <span style={{ ...statusStyle, ...currentStatus }}>
          {currentStatus.text}
        </span>
      );
    }
  };

  const tableColumns = useMemo(() => {
    return needShowStatus ? [...baseColumns, statusColumn] : baseColumns;
  }, [needShowStatus, i18n]);

  // 当 needShowStatus 改变时更新 activeTitle
  useEffect(() => {
    setActiveTitle(prev => {
      if (needShowStatus && !prev.includes('status')) {
        return [...prev, 'status'];
      }
      if (!needShowStatus && prev.includes('status')) {
        return prev.filter(item => item !== 'status');
      }
      return prev;
    });
  }, [needShowStatus]);

  const filters: FilterItem[] = React.useMemo(()=>[
    {
      name: 'date',
      type: 'dateRange',
      today
    },
    {
      name: 'queryType',
      type: 'select',
      allowClear: false,
      options: [
        { label: 'Sales Day', value: 1 },
        { label: 'Payout Day', value: 2 }
      ],
    }
  ], [today]);

  return (
    <div className='payoutReport'>
      <HeaderTitle
        title={ i18n.t('pc_payout_report_title') }
        rightContent={!isMobile && <Tooltip
          placement="topRight"
          overlayInnerStyle={ { width: 270 } }
          overlayStyle={ { inset: 'auto 60px 923px auto' } }
          title={ i18n.t('pc_payout_report_export_tips') }
        >
          <Button loading={ isExporting } onClick={ handleExport }>{ i18n.t('pc_payout_report_export') }</Button>
        </Tooltip>}
      />
      <div className='filter-wrap'>
        <TableToolbar
          filters={filters}
          filterValue={params}
          onFilterChange={handleChangeFilter}
          showColumnsList={activeTitle}
          columns={tableColumns as any}
          onChangeColumns={onChangeColumns}
          exportDom={<Button
            type="link"
            loading={isExporting}
            onClick={handleExport}
            icon={<i className="iconfont icon-export" />}
          />}
        />
      </div>
      <div className='summaryTitle'>{ i18n.t('pc_payout_report_Payout_overview') }</div>
      <div className='summaryWrap'>
        { summaryList.map((item, index) => (
          <div className='summaryItem' key={ index }>
            <div className='name'>{ item.title }</div>
            <div className='price'>{ item.value }</div>
          </div>)
        ) }
      </div>
      <div className='tableWrap'>
        <Table
          columns={ tableColumns.filter(x => {
            return activeTitle.includes(x.dataIndex)
          }) }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowKey={ 'key' }
          pagination={ pager }
          onChange={ handleTableChange }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          onRow={ handleTableRowClick }
          rowClassName='tableRow'
          loading={ loading }
        />
      </div>
    </div>
  )
}