import React, { useCallback, useState, useEffect } from 'react';
import { useGenericTable } from '@/hooks/useGenericTable';
import { GenericTablePage } from '@/Components/GenericTablePage';
import { getColumns, GiftCardTransactionTableData } from './columns';
import type { FilterItem } from '@/Components/FilterContainer/types';
import { globalState } from '@/stores';
import { Dayjs } from 'dayjs';
import { getRefundStatusOptions } from '@/constants/refund';
import { formatTimestampWithoutTimeZone } from '@/utils';
interface GiftCardTransactionProps {
  isActive: boolean;
  today: Dayjs | null;
}

const payType = {
  'value': '5',
  'label': 'Gift Card'
}

const TABLE_API_URL = '/rms/overseas/MerchantManagementConsole/reports/giftcard/transactionReport'
const EXPORT_API = '/rms/overseas/MerchantManagementConsole/reports/exportTransactionReport'

const GiftCardTransaction: React.FC<GiftCardTransactionProps> = (props: GiftCardTransactionProps) => {
  const { i18n } = globalState
  const { isActive, today } = props
  const columns = React.useMemo(() => getColumns(i18n), []);
  const initialFilterValues = React.useMemo(() => ({
    keyword: '',
    dateRange: [today?.subtract(6, 'day').startOf('day'), today],
    payType
  }), [today]);

  const filters = React.useMemo(()=>[
    {
      name: 'keyword',
      placeholder: i18n.t('pc_transaction_checkno_input_placeholder'),
      type: 'search',
    },
    {
      name: 'dateRange',
      type: 'dateRange',
      today,
    },
    {
      name: 'refundStatus',
      placeholder: i18n.t('Transactions_pc_refund_status'),
      type: 'select',
      labelInValue: true,
      options: getRefundStatusOptions(),
    }
  ], [today]);

  const {
    data,
    loading,
    pagination,
    params,
    setParams,
    refresh,
    handleTableChange,
    showColumnsList,
    isShowEmptyTable,
    handleChangeColumns
  } = useGenericTable<GiftCardTransactionTableData>({
    apiUrl: TABLE_API_URL,
    columns,
    defaultFilters: {
      request: {
        startTime: formatTimestampWithoutTimeZone(today?.subtract(6, 'day').startOf('day'), { format: 'YYYY-MM-DD 00:00:00' }),
        endTime: formatTimestampWithoutTimeZone(today, { format: 'YYYY-MM-DD 23:59:59' }),
        payType: {
          'value': '5',
          'label': 'Gift Card'
        }
      }
    }
  });

  useEffect(() => {
    if (isActive) {
      refresh();
    }
  }, [isActive]);

  const onFilterChange = useCallback((filters)=>{
    setParams({
      ...filters,
      payType
    });
  }, [setParams])

  return (
    <>
      <GenericTablePage<GiftCardTransactionTableData>
        columns={columns}
        filters={filters as FilterItem[]}
        initialFilterValues={initialFilterValues}
        exportOptions={{
          exportApi: EXPORT_API,
          showPCExport: true,
          exportParams: {
            beginTime: params?.request?.startTime,
            endTime: params?.request?.endTime
          },
          hiddenDropDown: true
        }}
        tableProps={{
          data,
          loading,
          pagination,
          showColumnsList,
          isShowEmptyTable
        }}
        handlers={{
          onFilterChange,
          onRefresh: refresh,
          onChangeColumns: handleChangeColumns,
          onTableChange: handleTableChange
        }}
      />
    </>

  );
};

export default React.memo(GiftCardTransaction);
