import React, { useMemo, useCallback, useEffect, use } from 'react';
import { Space, Form, Input, Select, TreeSelect } from 'antd';
import type { FilterContainerProps, SelectFilterItem } from './types';
import { useFilterOptions } from './useFilterOptions';
import { SearchOutlined } from '@ant-design/icons';
import PepprDatePicker from '@/Components/DatePicker';
import { SelectEmptyContent } from '@/Components/EmptyContent';
import { formatDate } from '@/utils';
import { useMobile } from '@/stores/MobileContext';


const DEFAULT_NAME = {
  dateRange: 'dateRange',
  search: 'search',
};

import './index.scss';

/**
 * 过滤器容器
 * @param filters 过滤器
 * @param onFilterChange 过滤器变化
 * @param initialValues 初始值
 * @param className 类名
 * @param style 样式
 */
const FilterContainer: React.FC<FilterContainerProps> = ({
  filters,
  onFilterChange,
  initialFilterValues,
  className,
  style,
  filterValue = null
}) => {
  const [form] = Form.useForm();
  const { optionsMap, loadingMap } = useFilterOptions(filters);
  const { isMobile } = useMobile();

  const filterItems = useMemo(() => {
    return filters.map((filter) => {
      const { disabled, type, name, placeholder } = filter;
      const options = type === 'select'
        ? (filter as SelectFilterItem).options || optionsMap[name]
        : undefined;
      const width = isMobile ? '100%' : (filter.width || 250);
      const commonProps = {
        style: { ...(filter?.style || {}), width },
        placeholder,
        disabled,
        width
      }
      let filterComponent;
      switch (type) {
      case 'select':
        filterComponent = (
          <Select
            {...commonProps}
            allowClear={filter.allowClear === undefined ? true : filter.allowClear}
            notFoundContent={ SelectEmptyContent }
            options={options}
            loading={loadingMap[name]}
            labelInValue={filter.labelInValue}
            optionFilterProp={filter.optionFilterProp}
            showSearch={filter.showSearch}
            mode={filter.mode}
            maxTagCount={filter.maxTagCount}
            maxTagPlaceholder={filter.maxTagPlaceholder}
          />
        );
        break;
      case 'dateRange':
        const datePickerProps = {
          disabledDate: filter?.disabledDate,
          style: commonProps.style,
          disabled: disabled,
          today: filter.today,
          ...(filter?.presets && { presets: filter.presets })
        }
        filterComponent = (
          <PepprDatePicker {...datePickerProps} />
        );
        break;
      case 'treeSelect':
        filterComponent = (
          <TreeSelect
            {...commonProps}
            popupClassName={filter.popupClassName}
            showSearch={filter.showSearch}
            treeCheckable={filter.treeCheckable}
            maxTagCount={ filter.maxTagCount }
            notFoundContent={ SelectEmptyContent }
            placeholder={ filter.placeholder }
            treeData={ filter.treeData }
            filterTreeNode={ (inputValue, treeNode) => filter.filterTreeNode?.(inputValue, treeNode) }
          />
        );
        break;
      case 'search':
      default:
        filterComponent = (
          <Input
            {...commonProps}
            allowClear
            prefix={<SearchOutlined />}
          />
        );
        break;
      }

      return (
        <Form.Item
          key={name}
          name={name || DEFAULT_NAME[type]}
          label={filter.label}
          tooltip={filter.tooltip}
        >
          {filterComponent}
        </Form.Item>
      );
    });
  }, [filters, optionsMap, loadingMap]);

  const handleValuesChange = useCallback((_: any, allValues: any) => {
    if (allValues.dateRange) {
      allValues.startTime = formatDate(allValues.dateRange[0]);
      allValues.endTime = formatDate(allValues.dateRange[1]?.endOf('day'));
      delete allValues.dateRange;
    }
    onFilterChange({ ...allValues });
  }, [onFilterChange]);

  useEffect(() => {
    filterValue && form.setFieldsValue({ ...filterValue });
  }, [filterValue])

  return (
    <div
      className={`filter-container ${className || ''}`}
      style={style}
    >
      <Form
        form={form}
        layout="inline"
        onValuesChange={handleValuesChange}
        initialValues={ initialFilterValues }
      >
        <Space wrap size={[16, 16]}>
          {filterItems}
        </Space>
      </Form>
    </div>
  );
};

export default React.memo(FilterContainer);