import React, { useEffect, useState } from 'react';
import { debounce } from '@/utils/throttleDebounce'
import { globalState } from '@/stores';
import { Button, Form, Input, Table, TreeSelect } from 'antd';
import { MoreOutlined, SyncOutlined } from '@ant-design/icons'
import { SelectEmptyContent, TableEmptyContent } from '@/Components/EmptyContent';
import dayjs from 'dayjs';
import useGetState from '@/hooks/useGetState';
import { ApiQueryAllDiningOptions, ApiQueryOrderReportViewList, ApiExportOrderReport } from '@/request/api';
import { formatBackI18n, formatDollar, formatPrice, formatTimestampWithoutTimeZone, getTimeZone, getToday as getTodayFn, removeNullUndefinedFields, toRes, formatParams } from '@/utils';
import { SorterResult } from 'antd/es/table/interface';
import GlobalDrawer from '@/utils/GlobalDrawer';
import OrderDetail from './OrderDetail'
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import './index.scss'
import { EReport_Status } from '@/types';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { IDropMenuExportParams, IExportResult } from '@/Components/Export/interface';
import HeaderTitle from '@/Components/HeaderTitle';
import { useMobile } from '@/stores/MobileContext';
import { TableToolbar } from '@/Components/TableToolbar';
import type { FilterItem } from '@/Components/FilterContainer/types';

interface IPage {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

const EXPORT_API = '/rms/overseas/MerchantManagementConsole/reports/exportOrderReport'

export default function OrdersReport () {
  const { i18n } = globalState;
  const navigate = useNavigate();
  const { isMobile } = useMobile();

  const [isInit, setIsInit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [sortedInfo, setSortedInfo, getSortedInfo] = useGetState<SorterResult<any>>({});
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [today, setToday, getToday] = useGetState(dayjs())
  const [diningOptionsList, setDiningOptionsList] = useState([])
  const [params, setParams, getParams] = useGetState({
    ascDecs: 0,
    orderStatus: '-1',
    date: [getToday().startOf('day'), getToday().endOf('day')],
    diningOptionIds: [],
    salesChannels: [],
    advancedSearchIdDisplay: ''
  })

  const ORDER_STATUS_TYPE = {
    ALL: { key: '-1', label: i18n.t('orderList_pc_all') },
    SAVED: { key: '100', label: i18n.t('orderList_pc_saved') },
    OPEN: { key: '200', label: i18n.t('rms_overseas_transaction_pay_PayStatus_OPEN') },
    FINISHED: { key: '300', label: i18n.t('orderList_pc_finished') },
    CANCELED: { key: '400', label: i18n.t('rms_overseas_transaction_order_OrderItemStatusEnum_CANCELLED') },
    CLOSED: { key: '500', label: i18n.t('orderList_pc_closed') },
  }


  const SERVICE_TYPES = [
    { value: 1, label: i18n.t('createMenuItem_pc_pos') },
    { value: 2, label: i18n.t('Dashboard_Orders_Online_Ordering') },
    { value: 3, label: i18n.t('dashboard_doordash') }
  ]
  const tableColumns = [
    { dataIndex: 'posDisplayNo',
      key: 'posDisplayNo',
      title: i18n.t('orders_table_check#'),
      width: 100 },
    { dataIndex: 'diningOptionName',
      key: 'diningOptionName',
      title: i18n.t('orders_table_diningOption'),
      width: 170 },
    // {
    //   dataIndex: 'orderStatus',
    //   key: 'orderStatus',
    //   title: i18n.t('orders_table_orderStatus'),
    //   width: 200,
    //   render: (val, row) => (
    //     <div className='colOrderStatus'>
    //       { formatBackI18n(val?.displayName, i18n) }
    //       { (row?.orderStatus?.value == 300 && row?.hasRefund?.value == 1) && <Tag>{i18n.t('dashboard_report_part_refund')}</Tag> }
    //     </div>)
    // },
    {
      dataIndex: 'standardCreateTime',
      key: 'standardCreateTime',
      title: i18n.t('orders_table_orderCreated'),
      width: 230,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'standardCreateTime' ? sortedInfo.order : null,
      render: (val) => {
        return formatTimestampWithoutTimeZone(val) || '-'
      }
    },
    {
      dataIndex: 'standardCloseTime',
      key: 'standardCloseTime',
      title: i18n.t('orders_table_orderClosed'),
      width: 230,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'standardCloseTime' ? sortedInfo.order : null,
      visible: () => ['300', '500', '-1'].includes(params.orderStatus),
      render: (val) => formatTimestampWithoutTimeZone(val) || '-'
    },
    {
      dataIndex: 'standardCancelTime',
      key: 'standardCancelTime',
      title: i18n.t('orders_table_orderCanceled'),
      width: 230,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'standardCancelTime' ? sortedInfo.order : null,
      visible: () => ['400', '-1'].includes(params.orderStatus),
      render: (val) => formatTimestampWithoutTimeZone(val) || '-'
    },
    {
      dataIndex: 'server',
      key: 'server',
      title: i18n.t('orders_table_server'),
      width: 100,
      render: (_, row) => row.server?.fullName || '-'
    },
    { dataIndex: 'tax',
      key: 'tax',
      title: i18n.t('orders_table_taxes'),
      width: 100,
      render: (val) => formatDollar(val) + formatPrice(val) },
    { dataIndex: 'tips',
      key: 'tips',
      title: i18n.t('orders_table_tips'),
      width: 100,
      render: (val) => formatDollar(val) + formatPrice(val) },
    { dataIndex: 'receivable',
      key: 'receivable',
      title: i18n.t('orders_table_total'),
      width: 100,
      render: (val) => formatDollar(val) + formatPrice(val) },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: i18n.t('orders_table_actions'),
      width: 100,
      fixed: 'right',
      render: (_, row) => <Button type="link" className='btnDetail' onClick={ () => handleOpenDetail(row) }><MoreOutlined/>{ i18n.t('details') }</Button>
    },
  ]

  const init = async () => {
    const _today = await getTodayFn({ timeZone: getTimeZone() })
    setToday(_today)
    setParams((prev) => ({ ...prev, date: [_today, _today] }));
    fetchDiningOptions();
    fetchTableData();
  }
  useEffect(() => {
    init()
  }, []);

  const fetchDiningOptions = async () => {
    const [_, data = []] = await toRes(ApiQueryAllDiningOptions());
    setDiningOptionsList(data.map(x => ({ value: x.id, title: x.name, key: x.id })))
  }

  const fetchTableData = async () => {
    const formParams = getParams();
    const sortParams = getSortedInfo();
    const pagerParams = getPager();
    let field = 'created_time';
    if (sortParams.order) {
      switch (sortParams.columnKey) {
      case 'standardCreateTime':
        field = 'created_time'
        break;
      case 'standardCloseTime':
        field = 'check_out_time'
        break;
      case 'standardCancelTime':
        field = 'cancel_time'
        break;
      default:
        break;
      }
    }

    const getOrderStatus = (orderStatus) => {
      switch (orderStatus) {
      case '100':
      case '200':
        return ['100', '200'];

      case '400':
        return ['400'];

      case '300':
      case '500':
        return ['300', '500'];

      default:
        return ['-1'];
      }
    }
    const result = {
      request: {
        advancedSearchIdDisplay: formParams.advancedSearchIdDisplay == '' ? null : formParams.advancedSearchIdDisplay,
        beginTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
        endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
        orderStatus: getOrderStatus(formParams.orderStatus),
        // timeSortType: getTimeSortType(),
        ...formatParams({ diningOptionIds: formParams.diningOptionIds, salesChannels: formParams.salesChannels }),
      },
      orderBy: {
        field: field,
        order: sortParams.order && sortParams.order === 'ascend' ? 'ASC' : 'DESC' // 原ascDesc字段，"ASC"(原1) OR "DESC"(原0)，默认倒序DESC
      },
      current: pagerParams.current,
      pageSize: pagerParams.pageSize
    }
    setLoading(true)
    const [_, data] = await toRes(ApiQueryOrderReportViewList(removeNullUndefinedFields(result)));
    setLoading(false)
    setIsInit(true);
    setTableData(data?.list ?? [])
    setPager({
      ...pager,
      pageSize: pagerParams.pageSize,
      total: data?.total ?? 0,
      current: data?.current ?? 1
    })
  }


  const handleFormValuesChange = (changedValues) => {
    setParams((prev) => ({ ...prev, ...changedValues }));
    setPager({ ...pager, current: 1 });
    fetchTableData();
  }

  const handleStatusChange = (val) => {
    setParams({ ...params, orderStatus: val.toString() })
    setPager({ ...pager, current: 1 });
    fetchTableData();
  }

  const handleTableChange = (_pagination, filters, sorter) => {
    console.log(_pagination, filters, sorter)
    setSortedInfo(sorter);
    setPager({ ...pager, ..._pagination });
    fetchTableData();
  }

  // const getTimeSortType = () => {
  //   const formParams = form.getFieldsValue();
  //   const sortParams = getSortedInfo();
  //   const sortTypeObj = {
  //     createTime: '100',
  //     cancelTime: '400',
  //     closeTime: '500'
  //   };
  //   let sortTypeCode;
  //   if (sortParams.columnKey == 'createTime') {
  //     sortTypeCode = sortTypeObj.createTime;
  //   } else {
  //     if (formParams.orderStatus === '-1' || formParams.orderStatus == '300' || !formParams.orderStatus) {
  //       sortTypeCode = '300';
  //     } else {
  //       sortTypeCode = sortTypeObj[sortParams.columnKey as keyof typeof sortTypeObj];
  //     }
  //   }
  //   return sortTypeCode
  // }

  const handleOpenDetail = (row) => {
    GlobalDrawer.open({ component: <OrderDetail/>, navigate: navigate, props: { checkNo: row?.checkNo } })
  }


  // 导出相关
  const fetchApiExport = async (exportType: IDropMenuExportParams) => {
    const params = {
      ...getRequestParams(),
      exportType: EReport_Status[exportType],
    }
    const res: any = await ApiExportOrderReport(removeNullUndefinedFields(params));

    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }

    return exportResult
  }


  const getRequestParams = () => {
    return {
      beginTime: params.date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: params.date[1].format('YYYY-MM-DD 23:59:59'),
    }
  }

  const filters: FilterItem[] = React.useMemo(()=>[
    {
      name: 'advancedSearchIdDisplay',
      type: 'search',
      placeholder: i18n.t('orders_search_placeholder'),
      width: 250
    },
    {
      name: 'date',
      type: 'dateRange',
      today
    },
    {
      name: 'diningOptionIds',
      type: 'treeSelect',
      popupClassName: 'diningOptionSelectPopup',
      width: 250,
      showSearch: true,
      treeCheckable: true,
      maxTagCount: 1,
      placeholder: i18n.t('select_x', { msg: i18n.t('pc_reports_orders_dining_options') }),
      treeData: diningOptionsList,
      filterTreeNode: (inputValue, treeNode) => treeNode.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    },
    {
      name: 'salesChannels',
      type: 'treeSelect',
      popupClassName: 'diningOptionSelectPopup',
      width: 250,
      showSearch: true,
      treeCheckable: true,
      placeholder: i18n.t('Dashboard_Orders_Select_Sales_Channels'),
      treeData: SERVICE_TYPES,
      filterTreeNode: (inputValue, treeNode) => treeNode.props.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    }
  ], [today, diningOptionsList, SERVICE_TYPES]);


  return (
    <div className='ordersReport'>
      <HeaderTitle
        defaultActiveKey={ ORDER_STATUS_TYPE.ALL.key }
        title={ i18n.t('pc_reports_orders_orders') }
        items={ [ORDER_STATUS_TYPE.ALL, ORDER_STATUS_TYPE.OPEN, ORDER_STATUS_TYPE.CLOSED, ORDER_STATUS_TYPE.CANCELED] }
        rightContent={!isMobile && <PepprExportComponent
          type='button'
          isDropDown={true}
          disabled={tableData.length === 0}
          fetchApiExport={(exportType) => fetchApiExport(exportType)}
        />}
        onChange={ handleStatusChange }
      />
      <TableToolbar
        filters={filters}
        filterValue={params}
        exportOptions={{
          exportApi: EXPORT_API,
          exportParams: {
            beginTime: params?.date[0].format('YYYY-MM-DD 00:00:00'),
            endTime: params?.date[1].format('YYYY-MM-DD 23:59:59')
          }
        }}
        onFilterChange={handleFormValuesChange}
        onRefresh={fetchTableData}
      />
      <div className='tableWrap'>
        <Table
          columns={ tableColumns.filter(x => x?.visible ? x.visible() : true) as ColumnsType }
          onChange={ handleTableChange }
          loading={ loading }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          pagination={ pager }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
        />
      </div>
    </div>
  )
}