import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios';
import { Button, Form, Input, message, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SelectEmptyContent, TableEmptyContent } from '@/Components/EmptyContent';
import {  formatFullPrice, removeNullUndefinedFields, formatBackI18n } from '@/utils';
import useGetState from '@/hooks/useGetState';
import { globalState } from '@/stores';
import { ApiExportPaymentTransactionReport, ApiQueryPaymentTransactionReport } from '@/request/api';
import { PAYMENT_STATUS_MAP, PAYMENT_STATUS_TYPE, REFUND_STATUS_MAP, REFUND_STATUS_TYPE } from '@/Pages/Transaction/enum';
import './index.scss'
import { formatTime } from '@/utils/index';
import { useMobile } from '@/stores/MobileContext';
import { EReport_Status } from '@/types';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { IExportResult } from '@/Components/Export/interface';
import { TableToolbar } from '@/Components/TableToolbar';
import type { FilterItem } from '@/Components/FilterContainer/types';
import { Dayjs } from 'dayjs';
interface IPage {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

let axiosSource = axios.CancelToken.source();

const EXPORT_API = '/rms/overseas/MerchantManagementConsole/reports/exportTransactionReport';

export default function Card (props) {
  const { i18n } = globalState;
  const { today, isActive } = props;
  const { isMobile } = useMobile()

  const [inited, setInited] = useState<boolean>(false)
  const [isShowEmptyTable, setIsShowEmptyTable] = useState(false)
  const [showColumnsList, setShowColumnsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })

  const [params, setParams] = useState<{
    date: [Dayjs, Dayjs],
    checkNo: string,
    paymentStatus?: string,
    refundStatus?: string,
  }>({
    date: [today?.subtract(6, 'day').startOf('day'), today],
    checkNo: ''
  })

  useEffect(() => {
    setShowColumnsList(tableColumns.filter(x => !x.hide).map(x => x.dataIndex))
  }, []);

  useEffect(() => {
    if (today) {
      setParams((prev) => ({ ...prev, date: [today.subtract(6, 'day').startOf('day'), today] }));
    }
  }, [today])

  useEffect(() => {
    if (!params.date?.[0]) return;
    fetchTableData();
  }, [params]);

  useEffect(() => {
    if (inited && isActive) {
      fetchTableData();
    }
  }, [isActive]);

  const tableColumns = [
    { dataIndex: 'orderId', key: 'orderId', title: i18n.t('pc_transaction_order_id'), render: val => val || '-' },
    { dataIndex: 'orderNo', key: 'orderNo', title: i18n.t('pc_transaction_order_no'), render: val => val || '-' },
    { dataIndex: 'orderDate', key: 'orderDate', title: i18n.t('pc_transaction_order_date'), render: val => {
      if (!!val) {
        return formatTime(val, 'MM/dd/yyyy hh:mm:ss tt');
      } else {
        return '-';
      }
    } },
    { dataIndex: 'checkId', key: 'checkId', title: i18n.t('pc_transaction_check_id'), render: val => val || '-' },
    { dataIndex: 'checkNo', key: 'checkNo', title: i18n.t('pc_transaction_check_no'), render: val => val || '-' },
    { dataIndex: 'amount', key: 'amount', title: i18n.t('pc_transaction_amount'), render: val => formatFullPrice(val) },
    { dataIndex: 'tip', key: 'tip', title: i18n.t('pc_transaction_tip'), render: val => formatFullPrice(val) },
    { dataIndex: 'total', key: 'total', title: i18n.t('pc_transaction_total'), render: val => formatFullPrice(val) },
    { dataIndex: 'paymentDate', key: 'paymentDate', title: i18n.t('pc_transaction_payment_date'), render: (val, row) => <>{ formatTime(val, 'MM/dd/yyyy hh:mm:ss tt') }</> },
    { dataIndex: 'paymentId', key: 'paymentId', title: i18n.t('pc_transaction_payment_id') },
    { dataIndex: 'paymentStatus', key: 'paymentStatus', title: formatBackI18n('pc_transaction_payment_status', i18n) },
    { dataIndex: 'cardLast4', key: 'cardLast4', title: i18n.t('trans_table_last4'), hide: true, render: val => val || '-' },
    { dataIndex: 'cardType', key: 'cardType', title: i18n.t('pc_transaction_card_type'), hide: true, render: val => val || '-' },
    { dataIndex: 'refundStatus', key: 'refundStatus', title: formatBackI18n('pc_transaction_refund_status', i18n), render: val => val || '-' },
    { dataIndex: 'refundDate', key: 'refundDate', title: i18n.t('pc_transaction_refund_date'), hide: true, render: val => val && formatTime(val, 'MM/dd/yyyy hh:mm:ss tt') || '-' },
    { dataIndex: 'refundAmount', key: 'refundAmount', title: i18n.t('pc_transaction_refund_amount'), hide: true, render: val => val ? formatFullPrice(val) : '-' },
  ]

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }

  const handleTableChange = (_pagination) => {
    setPager({ ...pager, ..._pagination });
    fetchTableData();
  }

  const handleFormValuesChange = (changedValues) => {
    setPager({ ...pager, current: 1 });
    setParams({ ...changedValues })
  }

  const fetchTableData = async () => {
    const pagerParams = getPager();
    const params = {
      request: {
        ...getRequestParams(),
      },
      orderBy: {
        field: 'pay_time',
        order: 'DESC'
      },
      current: pagerParams.current,
      pageSize: pagerParams.pageSize,
    }
    setLoading(true);
    axiosSource.cancel();
    axiosSource = axios.CancelToken.source();
    let resp;
    try {
      resp = await ApiQueryPaymentTransactionReport(removeNullUndefinedFields(params), { cancelToken: axiosSource.token });
    } catch (err) {
      if (axios.isCancel(err)) return;
      message.error(err.message);
    }
    setLoading(false);
    setInited(true);
    if (resp?.code !== 0) {
      return;
    }
    const data = resp?.data
    setTableData(data?.list ?? [])
    setPager({
      ...pagerParams,
      current: data.current,
      total: data.total,
    })
  }

  const getRequestParams = () => {
    const formParams = params;
    return {
      beginTime: formParams.date?.[0]?.format('YYYY-MM-DD 00:00:00'),
      endTime: formParams.date?.[1]?.format('YYYY-MM-DD 23:59:59'),
      // TODO: 参数逻辑后续梳理 report 统一优化
      checkNo: formParams?.checkNo?.length > 0 ? formParams.checkNo : null,
      paymentStatus: formParams?.paymentStatus,
      refundStatus: formParams?.refundStatus,
    }
  }

  // 导出相关
  const fetchApiExport = async () => {
    const params = {
      ...getRequestParams(),
      exportType: EReport_Status.XLS,
    }
    const res: any = await ApiExportPaymentTransactionReport(removeNullUndefinedFields(params));

    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }

    return exportResult
  }

  const filters: FilterItem[] = React.useMemo(()=>[
    {
      name: 'checkNo',
      type: 'search',
      placeholder: i18n.t('pc_transaction_checkno_input_placeholder')
    },
    {
      name: 'date',
      type: 'dateRange',
      today
    },
    {
      name: 'paymentStatus',
      type: 'select',
      options: [
        PAYMENT_STATUS_TYPE.VOIDED, PAYMENT_STATUS_TYPE.DECLINED, PAYMENT_STATUS_TYPE.PROCESSING,
        PAYMENT_STATUS_TYPE.AUTHORIZED, PAYMENT_STATUS_TYPE.CAPTURED
      ].map(x => ({ value: x, label: formatBackI18n(PAYMENT_STATUS_MAP[x], i18n) })),
      placeholder: i18n.t('Transactions_pc_payment_status')
    },
    {
      name: 'refundStatus',
      type: 'select',
      options: [
        REFUND_STATUS_TYPE.NA, REFUND_STATUS_TYPE.PARTILA_REFUNDED, REFUND_STATUS_TYPE.REFUNDED
      ].map(x => ({ value: x, label: formatBackI18n(REFUND_STATUS_MAP[x], i18n) })),
      placeholder: i18n.t('Transactions_pc_refund_status')
    }
  ], [today]);

  return (
    <div className='transaction-card'>
      {!isMobile && <div className='btn-wrap'>
        <PepprExportComponent
          type='button'
          disabled={ tableData.length === 0 }
          fetchApiExport={fetchApiExport}
        />
      </div>}
      <TableToolbar
        filters={filters}
        filterValue={params}
        exportOptions={{
          exportApi: EXPORT_API,
          exportParams: {
            beginTime: params?.date?.[0]?.format('YYYY-MM-DD 00:00:00'),
            endTime: params?.date?.[1]?.format('YYYY-MM-DD 23:59:59'),
          }
        }}
        onFilterChange={handleFormValuesChange}
        onRefresh={fetchTableData}
        showColumnsList={showColumnsList}
        columns={tableColumns as any}
        onChangeColumns={handleChangeColumns}
      />
      <div className="table-wrap">
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          rowKey={ 'key' }
          loading={ loading }
          pagination={ { ...pager, position:[isMobile ? 'bottomCenter' : 'bottomRight'], simple: isMobile } }
          onChange={ handleTableChange }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          summary={ () => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
                { TableEmptyContent }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          ) }
        />
      </div>
    </div>
  )
}