import { globalState } from '@/stores';
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Select, Table } from 'antd';
import { SelectEmptyContent, TableEmptyContent } from '@/Components/EmptyContent';
import useGetState from '@/hooks/useGetState';
import { downloadFile, errorOutput, formatBackI18n, formatFullPrice, formatTimestampWithoutTimeZone, removeNullUndefinedFields } from '@/utils';
import { ApiApaasQueryExportResult, ApiExportPaymentTransactionReport, ApiQueryTransactionList } from '@/request/api';
import { debounce } from '@/utils/throttleDebounce';
import PepprDatePicker from '@/Components/DatePicker';
import './index.scss'
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import Columns from '@/Components/Columns/Columns';
import { ColumnsType } from 'antd/es/table';
import { EReport_Status } from '@/types';
import { useMobile } from '@/stores/MobileContext';
import { FilterItem } from '@/Components/FilterContainer/types';
import { TableToolbar } from '@/Components/TableToolbar';

interface IPage {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
  showQuickJumper: boolean;
  showTotal: (total: number) => string;
}

const EXPORT_API = '/rms/overseas/MerchantManagementConsole/reports/exportTransactionReport';

function Cash (props) {
  const { i18n } = globalState;
  const { isActive, today } = props
  const { isMobile } = useMobile()

  const [isInit, setIsInit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pager, setPager, getPager] = useGetState<IPage>({
    current: 1, pageSize: 10, total: 0, showSizeChanger: true, showQuickJumper: true,
    showTotal: (total) => {
      return i18n.t('table_total_items', { num: total });
    },
  })
  const [tableData, setTableData] = useState([])
  const [showColumnsList, setShowColumnsList] = useState<Array<string>>([])
  const [isShowEmptyTable, setIsShowEmptyTable] = useState<boolean>(false)

  const [isExporting, setIsExporting] = useState(false)
  const [intervalId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [params, setParams] = useState({
    date: [today.subtract(6, 'day').startOf('day'), today],
    keyword: '',
    refundStatus: undefined
  })

  let exportTaskId;

  const REFUND_STATUS_TYPE = {
    NOT_REFUNDED: { value: '0', label: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_NOT_REFUNDED') },
    PARTIALLY_REFUNDED: { value: '1', label: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_PARTIALLY_REFUNDED') },
    FULLY_REFUNDED: { value: '2', label: i18n.t('rms_overseas_transaction_order_RefundStatusEnum_FULLY_REFUNDED') }
  }

  const tableColumns = [
    { dataIndex: 'posDisplayCheckNo', key: 'posDisplayCheckNo', title: i18n.t('trans_check_no') },
    { dataIndex: 'standardCreatedTime', key: 'standardCreatedTime', title: i18n.t('trans_table_orderingTime'), render: (val) => formatTimestampWithoutTimeZone(val) },
    { dataIndex: 'orderIdLast6', key: 'orderIdLast6', title: i18n.t('trans_table_order#') },
    { dataIndex: 'tipAmount', key: 'tipAmount', title: i18n.t('trans_table_tips'), render: (val) => formatFullPrice(val) },
    { dataIndex: 'payed', key: 'payed', title: i18n.t('trans_table_paidTotal'), render: (val) => formatFullPrice(val) },
    { dataIndex: 'refundStatus', key: 'refundStatus', title: i18n.t('trans_table_refundStatus'), render: (val) => formatBackI18n(val?.displayName, i18n) || '-' },
    { dataIndex: 'refundTotal', key: 'refundTotal', title: i18n.t('trans_table_refundTotal'), render: (val) => val || Number(val) > 0 ? formatFullPrice(val) : '-' }
  ]

  useEffect(() => {
    if (isActive) {
      setShowColumnsList(tableColumns.map(x => x.dataIndex))
      fetchTableData();
    }
  }, [isActive]);

  useEffect(() => {
    if (today) {
      fetchTableData();
    }
  }, [today, params])

  const handleFormValuesChange = (changedValues) => {
    setPager({ ...pager, current: 1 });
    setParams({ ...params, ...changedValues })
  }

  const fetchTableData = async () => {
    const formParams = params
    const pagerParams = getPager();
    const result = {
      request: {
        payType: { value: '1', label: 'Cash' },
        keyword: formParams.keyword,
        startTime: formatTimestampWithoutTimeZone(formParams.date[0].startOf('day').valueOf(), { format: 'YYYY-MM-DD HH:mm:ss' }),
        endTime: formatTimestampWithoutTimeZone(formParams.date[1].endOf('day').valueOf(), { format: 'YYYY-MM-DD HH:mm:ss' }),
        refundStatus: Object.values(REFUND_STATUS_TYPE).find(x => x.value === formParams.refundStatus),
      },
      orderBy: {
        field: 'created_time',
        order: 'DESC'
      },
      current: pagerParams.current,
      pageSize: pagerParams.pageSize
    }
    setLoading(true)
    const resp: any = await ApiQueryTransactionList(result);
    setIsInit(true)
    setLoading(false)
    if (resp.code !== 0) return;
    const data = resp?.data;
    setTableData(data.list ?? [])
    setPager({
      ...pager,
      pageSize: data.pageSize,
      total: data.total,
      current: data.current ?? 1
    })
  }

  const handleTableChange = (_pagination) => {
    setPager({ ...pager, ..._pagination, pageNo: _pagination.current });
    fetchTableData();
  }

  const handleChangeColumns = (list) => {
    setIsShowEmptyTable(list.length === 0)
    setShowColumnsList(list);
  }

  // region 导出相关
  const handleExport = async () => {
    setIsExporting(true)
    const params = {
      ...getRequestParams(),
      exportType: EReport_Status.XLS,
    }
    try {
      const resp: any = await ApiExportPaymentTransactionReport(removeNullUndefinedFields(params));
      exportTaskId = resp?.data?.exportTaskId ?? null;
      if (resp?.code !== 0 || !exportTaskId) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        return;
      }
      getExportResult();
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
    }
  }

  const getRequestParams = () => {
    const formParams = params;
    return {
      beginTime: formParams.date[0].format('YYYY-MM-DD 00:00:00'),
      endTime: formParams.date[1].format('YYYY-MM-DD 23:59:59'),
    }
  }

  const getExportResult = async () => {
    try {
      const resp: any = await ApiApaasQueryExportResult({ params: { req: { exportTaskId } } });
      if (resp.code !== 0) {
        errorOutput(resp, i18n.t('pc_payout_report_download_failed'), i18n)
        setIsExporting(false)
        stopExport();
        return;
      }
      const data = resp.data;
      if (data?.result && data?.url) {
        // 开启下载
        downloadFile(data.url);
        message.success(i18n.t('pc_payout_report_successfully_downloaded'))
        setIsExporting(false);
        exportTaskId = null;
      } else {
        const id = setTimeout(async () => {
          getExportResult();
        }, 2000);
        setTimeoutId(id);
      }
    } catch (err) {
      errorOutput(err, i18n.t('pc_payout_report_download_failed'), i18n)
      setIsExporting(false)
      stopExport();
    }
  }

  const stopExport = () => {
    if (intervalId !== null) {
      clearTimeout(intervalId);
      setTimeoutId(null);
    }
  }

  const filters: FilterItem[] = React.useMemo(()=>[
    {
      name: 'keyword',
      type: 'search',
      placeholder: i18n.t('Transactions_pc_order#')
    },
    {
      name: 'date',
      type: 'dateRange',
      today
    },
    {
      name: 'refundStatus',
      type: 'select',
      options: [REFUND_STATUS_TYPE.NOT_REFUNDED, REFUND_STATUS_TYPE.PARTIALLY_REFUNDED, REFUND_STATUS_TYPE.FULLY_REFUNDED],
      placeholder: i18n.t('Transactions_pc_refund_status')
    }
  ], [today]);

  return (
    <div className="transaction-cash">
      {
        !isMobile && <div className="btn-wrap">
          <Button
            type="primary"
            loading={ isExporting }
            onClick={ () => handleExport() }
            disabled={tableData.length === 0}
          >
            { i18n.t('export_pc') }
          </Button>
        </div>
      }
      <TableToolbar
        filters={filters}
        initialFilterValues={params}
        exportOptions={{
          exportApi: EXPORT_API,
          exportParams: getRequestParams()
        }}
        onFilterChange={handleFormValuesChange}
        onRefresh={fetchTableData}
        showColumnsList={showColumnsList}
        columns={tableColumns as any}
        onChangeColumns={handleChangeColumns}
      />
      <div className="table-wrap">
        <Table
          className={ isShowEmptyTable && 'empty-table' }
          columns={ tableColumns.filter(x => showColumnsList.includes(x.dataIndex)) as ColumnsType }
          onChange={ handleTableChange }
          loading={ loading }
          dataSource={ tableData.map((x, i) => ({ ...x, key: i })) }
          pagination={ pager }
          scroll={ { x: 'max-content' } }
          locale={ { emptyText: TableEmptyContent } }
          summary={ () => (
            isShowEmptyTable && <Table.Summary.Row>
              <Table.Summary.Cell index={ 0 } colSpan={ 999 }>
                { TableEmptyContent }
              </Table.Summary.Cell>
            </Table.Summary.Row>
          ) }
        />
      </div>
    </div>
  )
}
export default React.memo(Cash);