import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd'
import { globalState } from '@/stores';
import { getCalendarToday } from '@/utils';
import { Dayjs } from 'dayjs';
import Card from './Card'
import Cash from './Cash';
import GiftCardTransaction from './GiftCard';
import HeaderTitle from '@/Components/HeaderTitle';
enum TAB_TYPE {
  CARD = 'CARD',
  CASH = 'CASH',
  GIFT_CARD = 'GIFT_CARD'
}

export default function Transaction () {
  const { i18n } = globalState;
  const [tabValue, setTabValue] = useState(TAB_TYPE.CARD)
  const [today, setToday] = useState<Dayjs | null>(null)
  const tabsItems = [
    {
      key: TAB_TYPE.CARD,
      label: i18n.t('trans_card'),
      children: <Card isActive={ tabValue === TAB_TYPE.CARD } today={ today }/>
    },
    {
      key: TAB_TYPE.CASH,
      label: i18n.t('trans_cash'),
      children: <Cash isActive={ tabValue === TAB_TYPE.CASH } today={ today } />
    },
    {
      key: TAB_TYPE.GIFT_CARD,
      label: i18n.t('overseas_peppr_entrance_giftcard'),
      children: <GiftCardTransaction isActive={ tabValue === TAB_TYPE.GIFT_CARD } today={ today } />
    }
  ]

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setToday(await getCalendarToday())
  }

  const changeTab = (value) => {
    setTabValue(value);
  }


  return (
    <div className="transaction">
      <HeaderTitle title={ i18n.t('Transactions_pc_transactions') } items={ tabsItems } onChange={ changeTab } />
    </div>
  )
}