import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Button } from 'antd';
import { SyncOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import FilterContainer from '@/Components/FilterContainer';
import type { FilterItem } from '@/Components/FilterContainer/types';
import Columns from '@/Components/Columns/Columns';
import type { TableColumns } from '@/types/table';
import './index.scss';
import PepprExportComponent from '@/Components/Export/PepprExportComponent';
import { EReport_Status } from '@/types';
import { service } from '@/request';
import { IExportResult } from '@/Components/Export/interface';
import ReactToPrint from 'react-to-print';
import { getCookie } from '@/utils';
import { useMobile } from '@/stores/MobileContext';

export type ExportOptions = {
  exportApi?: string;
  exportParams?: Record<string, any>;
  showPCExport?: boolean;
  hiddenDropDown?: boolean;
}

interface TableToolbarProps<T> {
  filters?: FilterItem[];
  initialFilterValues?: Record<string, any>;
  columns?: TableColumns<T>;
  showColumnsList?: string[];
  onFilterChange?: (values: Record<string, any>) => void;
  onRefresh?: () => void;
  onChangeColumns?: (list: string[]) => void;
  extraActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  exportOptions?: ExportOptions;
  printRef?: React.RefObject<HTMLDivElement>,
  wrapperClassName?: string;
  formatColumns?: { label: string, value: string | number }[]
  exportDom?: React.ReactNode;
  filterValue?: Record<string, any>
}

const EXPORT_MAP = {
  'CSV': EReport_Status.CSV,
  'XLS': EReport_Status.XLS
}

export const TableToolbar = <T, >({
  filters,
  initialFilterValues,
  columns,
  showColumnsList,
  onFilterChange,
  onRefresh,
  onChangeColumns,
  extraActions,
  leftActions,
  exportOptions,
  printRef,
  formatColumns,
  exportDom,
  filterValue
}: TableToolbarProps<T>) => {
  const { isMobile } = useMobile();
  const tableToolbarRef = useRef<HTMLDivElement>(null);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = useCallback(() => {
    setIsCollapsed(prev => !prev);
  }, [])

  useEffect(() => {
    if (!tableToolbarRef?.current) return;

    const parentElement = document.getElementById('header-tabs')?.getElementsByClassName('ant-tabs-nav')?.[0];
    if (!parentElement) return;
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (tableToolbarRef.current && entry.contentRect.height < 200) {
          tableToolbarRef.current.style.top = `${entry.contentRect.height}px`;
        }
      }
    });

    resizeObserver.observe(parentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (printRef?.current && tableToolbarRef?.current) {
      tableToolbarRef.current.style.top = '0';
    }
  }, [tableToolbarRef, printRef]);


  // 导出相关
  const fetchApiExport = useCallback(async (exportType: string) => {
    const res: any = await service({
      method: 'post',
      url: exportOptions?.exportApi,
      data: {
        exportType: EXPORT_MAP[exportType] ?? EReport_Status.XLS,
        ...(exportOptions.exportParams || {})
      }
    })
    const exportResult: IExportResult = {
      exportStatus: res.code === 0,
      exportId: res?.data?.exportTaskId ?? null,
      exportFailErrorMsg: res,
    }
    return exportResult
  }, [exportOptions])

  return (
    <>
      {
        exportOptions?.showPCExport && !isMobile &&
          <div className="btn-wrap">
            <PepprExportComponent
              type='button'
              isDropDown={exportOptions?.hiddenDropDown ? false : true}
              fetchApiExport={fetchApiExport}
            />
          </div>
      }
      <div className='table-toolbar' ref={tableToolbarRef}>
        <div className={`table-toolbar-left ${isCollapsed ? 'collapsed' : ''}`}>
          {onFilterChange && filters && (
            <FilterContainer
              filters={filters}
              filterValue={filterValue}
              onFilterChange={onFilterChange}
              initialFilterValues={initialFilterValues}
            />
          )}
          {leftActions}
        </div>
        <div className='table-toolbar-right'>
          {isMobile && (
            <Button type="link" className={`up-outlined ${isCollapsed ? 'collapsed' : ''}`} onClick={handleCollapse}>
              {isCollapsed ? <DownOutlined /> : <UpOutlined />}
            </Button>
          )}
          {onRefresh && (
            <Button type="link" onClick={onRefresh}>
              <SyncOutlined />
            </Button>
          )}
          {onChangeColumns && (columns || formatColumns) && showColumnsList && (
            <Columns
              value={showColumnsList}
              options={ formatColumns || columns.map(item => ({
                label: item.title as string,
                value: item.dataIndex as string
              }))}
              onChange={onChangeColumns}
            />
          )}
          {
            (getCookie('appCode') !== '1' &&  printRef?.current)  && <ReactToPrint
              trigger={ () => <Button type="link" icon={ <div className="iconfont icon-print"/> }/> }
              content={ () => printRef.current }
            />
          }
          {isMobile && (exportOptions || exportDom) && (
            exportDom ||
          <PepprExportComponent
            type='icon'
            isDropDown={exportOptions?.hiddenDropDown ? false : true}
            fetchApiExport={fetchApiExport}
          />
          )}
          {extraActions}
        </div>
      </div>
    </>
  );
};