import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { globalState } from '@/stores';
import './index.scss'
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import classNames from 'classnames';
import { useMobile } from '@/stores/MobileContext';
import { MobileDatePicker } from '@/Components/MobileDatePicker';

export const DATE_RANGE_TYPE = {
  TODAY: '1',
  YESTERDAY: '2',
  THIS_WEEK: '3',
  LAST_7_DAYS: '7',
  LAST_WEEK: '4',
  THIS_MONTH: '5',
  LAST_MONTH: '6',
  CUSTOM: '7'
}

interface IProps extends RangePickerProps {
  initToday?: boolean;
  today?: dayjs.Dayjs;
  setPresets?: (value: any) => void;
  timeFrame?: number;
  selfPresets?: any[];
}

function PepprDatePicker (props: IProps) {
  const { i18n } = globalState;
  const { initToday = true, today = false, timeFrame = 6, style = {}, setPresets, ...dateProps } = props;
  const [rangePresets, setRangePresets] = useState([])
  const { isMobile } = useMobile();


  useEffect(() => {
    if (!today && today !== false) return;
    const _today = today || dayjs();
    const _rangePresets = dateProps?.selfPresets ?? [
      { key: DATE_RANGE_TYPE.TODAY, label: i18n.t('pc_employee_report_today'), value: [_today, _today] },
      { key: DATE_RANGE_TYPE.YESTERDAY, label: i18n.t('pc_employee_report_yesterday'), value: [_today.subtract(1, 'day'), _today.subtract(1, 'day')] },
      { key: DATE_RANGE_TYPE.THIS_WEEK, label: i18n.t('pc_employee_report_this_week'), value: [_today.startOf('week'), _today.endOf('week')] },
      { key: DATE_RANGE_TYPE.LAST_7_DAYS, label: i18n.t('pc_employee_report_last_7_days'), value: [_today.subtract(6, 'day').startOf('day'), _today] },
      { key: DATE_RANGE_TYPE.LAST_WEEK, label: i18n.t('pc_employee_report_last_week'), value: [_today.subtract(1, 'week').startOf('week'), _today.subtract(1, 'week').endOf('week')] },
      { key: DATE_RANGE_TYPE.THIS_MONTH, label: i18n.t('pc_employee_report_this_month'), value: [_today.startOf('month'), _today.endOf('month')] },
      { key: DATE_RANGE_TYPE.LAST_MONTH, label: i18n.t('pc_employee_report_last_month'), value: [_today.subtract(1, 'month').startOf('month'), _today.startOf('month').subtract(1, 'day')] }
    ]
    setPresets?.(_rangePresets)
    setRangePresets(_rangePresets)
  }, [today]);

  const disabledDate = (current, { from }: any) => {
    if (from) {
      return Math.abs(current.diff(from, 'month')) >= timeFrame;
    }
    return false;
  };

  const dateCellRender = (current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{ current }</div>;
    }
    return (
      <div className={ classNames('ant-picker-cell-inner', { 'ant-picker-cell-inner-today': current.isSame(today, 'day') }) }>
        { current.date() }
      </div>
    );
  }

  return (
    <>
      {
        isMobile ?
          // @ts-expect-error type error
          <MobileDatePicker
            presets={rangePresets}
            {...dateProps}
          /> :
          <DatePicker.RangePicker
            popupClassName="range-picker-date-popup"
            className="range-picker-date"
            inputReadOnly
            presets={ rangePresets }
            disabledDate={ disabledDate }
            format={ 'MM/DD/YYYY' }
            style={ { width: 260, ...(style || {}) } }
            allowClear={ false }
            cellRender={ dateCellRender }
            { ...dateProps }
          />
      }
    </>
  )
}

export default PepprDatePicker;