import useGetState from '@/hooks/useGetState';
import {
  APPROVAL_ENUM,
  DEVICE_TYPE_ENUM,
  IBusinessHour,
  IPickupConfig,
  KITCHEN_CAPACITY_ENUM,
  KITCHEN_CAPACITY_ENUM_I18N,
  QUOTE_TIME_ENUM,
  QUOTE_TIME_ENUM_I18N,
  OO_QUOTE_TIME_ENUM_I18N,
  SCHEDULING_ORDERS_ENUM,
  SCHEDULING_ORDERS_ENUM_I18N,
  UTENSIL_ENUM,
  UTENSIL_ENUM_I18N
} from '@/Pages/OnlineOrders/interface';
import { ApiOnlineOrderConfigQuery, ApiOnlineOrderConfigSave } from '@/request/api';
import { queryDevices } from '@/request/PrintersAndCashDrawers';
import { globalState } from '@/stores';
import { to } from '@/utils';
import copyText from '@/utils/copyText';
import NP from '@/utils/NP';
import { CopyOutlined, ExclamationCircleOutlined, FormOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, message, Popover, Radio, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import PickupHours from '../components/PickupHours';
import PickupUrlDialog from '../components/PickupUrlDialog';
import './index.scss';
import React from 'react';
import OrderPriceRules from '../components/OrderPriceRules';

interface ISelectProps {
  label: string;
  value: string
}
interface IForm {
  pickUpSwitch: boolean,
  schedulingOrdersMode: SCHEDULING_ORDERS_ENUM,
  quoteTimeMode: QUOTE_TIME_ENUM,
  quoteTimeCustomMin: number,
  quoteTimeCustomHour: number,
  approvalMode: APPROVAL_ENUM,
  kitchenCapacityMode: KITCHEN_CAPACITY_ENUM,
  utensilCondimentMode: UTENSIL_ENUM
  pickup_online_order_url: string,
  pickup_pos_device_sns: string[]
  pickup_hours: IBusinessHour[],
  orderPriceRules: string; // 新增字段，用于存储订单价格规则
}

const TIME_BASE = dayjs('1970-01-02 00:00:00.000').valueOf();

export default function Pickup () {
  const { i18n } = globalState;
  const [form] = Form.useForm<IForm>()
  const [isInit, setIsInit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const backupDataRef = useRef<string>('');
  const [pickupHoursList, setPickupHoursList, getPickupHoursList] = useGetState<Array<IBusinessHour>>([])
  const [deviceList, setDeviceList] = useState<Array<ISelectProps>>([]) // 设备列表
  const [deviceListLoading, setDeviceListLoading] = useState<boolean>(false)
  const watchedQuoteTimeMode = Form.useWatch(['quoteTimeMode'], form);
  const [isUrlModalOpen, setIsUrlModalOpen] = useState<boolean>(false)
  const [validationStatus, setValidationStatus] = useState<{
    hasError: boolean;
    hasWarning: boolean;
  }>({
    hasError: false,
    hasWarning: false,
  });

  // Schedule选项列表
  const ScheduleOrderOptions = [
    { value: SCHEDULING_ORDERS_ENUM.ASAP, label: i18n.t(SCHEDULING_ORDERS_ENUM_I18N[SCHEDULING_ORDERS_ENUM.ASAP]) },
    { value: SCHEDULING_ORDERS_ENUM.SCHEDULE_ORDER, label: i18n.t(SCHEDULING_ORDERS_ENUM_I18N[SCHEDULING_ORDERS_ENUM.SCHEDULE_ORDER]) }
  ]

  useEffect(() => {
    if (!isInit) {
      fetchDetail();
    }
  }, []);

  const fetchDetail = async () => {
    setLoading(true);
    const res = await ApiOnlineOrderConfigQuery();
    setLoading(false);
    if (!res?.data) return;
    backupDataRef.current = JSON.stringify(res.data);
    initForm();
    setIsInit(true);
  }
  // const secondsToDayjs = (sec) => {
  //   return dayjs(NP.plus(NP.times(sec, 1000), TIME_BASE));
  // }

  // const dayjsToSeconds = (dayjsObj) => {
  //   return Math.floor(NP.divide(NP.minus(dayjsObj.valueOf(), TIME_BASE), 1000));
  // }
  const initForm = () => {
    const data: IPickupConfig = JSON.parse(backupDataRef.current);
    const {
      PICK_UP_SWITCH,
      PICK_UP_HOURS,
      PICK_UP_SCHEDULING_ORDERS,
      PICK_UP_QUOTE_TIME,
      PICK_UP_APPROVAL,
      PICK_UP_CUSTOMER_DEMAND,
      PICK_UP_FIRE,
      PICK_UP_ONLINE_ORDER_URL,
      PICK_UP_POS_DEVICE_SNS
    } = data;
    const { hours: quoteTimeCustomMin, minutes: quoteTimeCustomHour } = getHoursAndMinutes(PICK_UP_QUOTE_TIME.customMinutes)
    const orderPriceRules = (PICK_UP_QUOTE_TIME.rules || []).map(rule => {
      const { hour, mins } = calculateTotalMinutes(
        Math.floor(rule.factor / 60),
        rule.factor % 60
      );
      return {
        dollarAmount: rule.threshold,
        hour,
        mins
      };
    });
    form.setFieldsValue({
      pickUpSwitch: PICK_UP_SWITCH,
      schedulingOrdersMode: PICK_UP_SCHEDULING_ORDERS.mode,
      quoteTimeMode: PICK_UP_QUOTE_TIME.mode,
      quoteTimeCustomMin,
      quoteTimeCustomHour,
      // approvalMode: PICK_UP_APPROVAL.mode,
      kitchenCapacityMode: PICK_UP_FIRE?.mode || 1,
      utensilCondimentMode: PICK_UP_CUSTOMER_DEMAND?.utensilCondimentMode || 1,
      pickup_online_order_url: PICK_UP_ONLINE_ORDER_URL || '',
      pickup_pos_device_sns: PICK_UP_POS_DEVICE_SNS,
      pickup_hours: PICK_UP_HOURS,
      orderPriceRules: orderPriceRules.length > 0
        ? JSON.stringify(orderPriceRules, null, 2)
        : ''
    });
    // setPickupHoursList(PICK_UP_HOURS.map(x => ({
    //   ...x,
    //   intervalTimes: x.intervalTimes.map(time => ({ startTime: secondsToTimeString(time.startTime), endTime: secondsToTimeString(time.endTime) }))
    // })));
  }
  const timeStringToMilliseconds = (timeString) => {
    // 解析时间字符串
    const timeParts = timeString.match(/(\d{2}):(\d{2})\s?(AM|PM)/i);
    if (!timeParts) {
      throw new Error('Invalid time format');
    }

    let hours = parseInt(timeParts[1], 10);
    const minutes = parseInt(timeParts[2], 10);
    const period = timeParts[3].toUpperCase();

    // 如果是 PM 且小时数小于 12，则加上 12 小时
    if (period === 'PM' && hours < 12) {
      hours += 12;
    }

    // 如果是 AM 且小时数为 12，则将小时数设为 0
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    // 计算自午夜以来的秒数
    const milliseconds = (hours * 60 * 60) + (minutes * 60);
    return milliseconds;
  }
  const secondsToTimeString = (seconds) => {
    // 计算小时、分钟和剩余秒数
    let hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    // 确定 AM 或 PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // 将24小时制转换为12小时制
    hours = hours % 12;
    hours = hours === 0 ? 12 : hours; // 将0点转换为12点

    // 格式化小时和分钟为两位数
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // 组合成时间字符串
    const timeString = `${formattedHours}:${formattedMinutes} ${period}`;

    return timeString;
  }
  const getHoursAndMinutes = (totalMinutes) => {
    const maxHours = 999;
    let hours = Math.floor(NP.divide(totalMinutes, 60));
    let minutes = totalMinutes % 60;

    if (hours >= maxHours) {
      minutes = NP.plus(minutes, NP.times(NP.minus(hours, maxHours), 60));
      hours = maxHours;
    }

    return { hours, minutes };
  }
  // 新增的时间24h&60min限制函数
  const calculateTotalMinutes = (hour: number, mins: number) => {
    if (hour === 24 && mins <= 60) {
      return { hour: 24, mins };
    }
    const total = hour * 60 + mins;
    if (total >= 24 * 60 + 60) {
      return { hour: 24, mins: 60 };
    }
    return { hour, mins };
  };
  //复制OO Url
  const handleUrlCopy = () => {
    const url = form.getFieldValue('pickup_online_order_url');
    copyText(url).then(() => {
      message.success(i18n.t('PC_OO_URL_Copy_Success'));
    }).catch(() => {
      message.error(i18n.t('PC_OO_URL_Copy_Fail'));
    })
  }
  const [url, setUrl] = useState(form.getFieldValue('pickup_online_order_url'));

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // 处理url change
  const handleUrlChange = (newUrl: string) => {
    form.setFieldValue('pickup_online_order_url', newUrl);
    setUrl(newUrl);
  };

  const handleUrlClose = () => setIsUrlModalOpen(false)
  // 拉取当前可用的Pos List
  const queryDeviceList = async () => {
    setDeviceListLoading(true);
    const res = await queryDevices({ typeList: [DEVICE_TYPE_ENUM.POS] });
    setDeviceListLoading(false);
    if (!res?.data) return;
    const result = res.data.map(item => ({
      label: item?.sn,
      value: item?.sn
    }))
    setDeviceList(result);
  }
  // 下拉框展示时拉取数据
  const handleDropdownVisibleChange = (open: boolean) => {
    if (open) {
      queryDeviceList()
    }
  }
  const formRules = {
    quoteTimeMode: [() => ({
      validator (_, value) {
        // if (value === QUOTE_TIME_ENUM.CUSTOM && form.getFieldValue('quoteTimeCustomMin') === 0 && form.getFieldValue('quoteTimeCustomHour') === 0) {
        //   return Promise.reject(new Error(i18n.t('pc_online_orders_quote_time_cannot')))
        // }
        return Promise.resolve()
      },
    })],
    getPickupHoursRules: (_index) => {
      return [() => ({
        validator (_, value) {
          const _pickupHoursList = getPickupHoursList();
          const pickupHourItem = _pickupHoursList[_index];
          const startTime = pickupHourItem.intervalTimes[0].startTime;
          const endTime = pickupHourItem.intervalTimes[0].endTime;
          if (startTime === endTime && pickupHourItem.openSwitch) {
            return Promise.reject(new Error(i18n.t('pc_online_orders_quote_time_error')))
          }
          return Promise.resolve()
        },
      })]
    }
  }

  const handleEdit = () => {
    if (!backupDataRef.current) return;
    setIsEdit(true);
  }

  const handleCancel = () => {
    // FIXME 之后还是要改造一下这个重置流程
    form.resetFields(['pickup_hours'])
    initForm();
    clearError();
    setIsEdit(false);
  }

  const clearError = () => {
    const allFormName = Object.keys(form.getFieldsValue())
    form.setFields(allFormName.map(x => ({ name: x as any, errors: [] })))
  }

  const handleSave = async () => {
    const [err, formParams] = await to(form.validateFields());
    if (err) return;
    const {
      pickUpSwitch,
      schedulingOrdersMode,
      quoteTimeMode,
      quoteTimeCustomMin,
      quoteTimeCustomHour,
      // approvalMode,
      utensilCondimentMode,
      kitchenCapacityMode,
      pickup_pos_device_sns,
      pickup_hours,
      orderPriceRules
    } = formParams;
    // 解析 orderPriceRules
    let rules = [];
    if (quoteTimeMode === QUOTE_TIME_ENUM.ORDER_PRICE) {
      try {
        //!orderPriceRules 这种校验其实没有被命中，而命中了其他的校验逻辑
        if (!orderPriceRules || validationStatus.hasError || validationStatus.hasWarning) {
          message.error( 'Save blocked – incomplete information' )
          return;
        }
        const parsedRules = JSON.parse(orderPriceRules || '[]');

        // 规则必须是非空数组
        if (!Array.isArray(parsedRules)) {
          return;
        }

        // 验证每条规则
        const isValid = parsedRules.every(rule =>
          typeof rule.dollarAmount === 'number' && rule.dollarAmount > 0 && (typeof rule.hour === 'number' || typeof rule.mins === 'number') && (rule.hour! > 0 || rule.mins! > 0) // 至少有一个时间字段大于0
        );

        if (!isValid) {
          return;
        }

        rules = parsedRules.map(rule => ({
          ruleType: 1,
          threshold: rule.dollarAmount,
          factor: (rule.hour || 0) * 60 + (rule.mins || 0)
        }));
      } catch (e) {
        return;
      }
    }
    console.log(formParams, 'formParams')
    const result = {
      PICK_UP_SWITCH: pickUpSwitch,
      PICK_UP_SCHEDULING_ORDERS: {
        mode: schedulingOrdersMode
      },
      PICK_UP_QUOTE_TIME: {
        mode: quoteTimeMode,
        customMinutes: quoteTimeCustomMin * 60 + quoteTimeCustomHour,
        rules
      },
      // TODO: 先写死mode=2是自动接单，后续可能会有新的功能迭代
      PICK_UP_APPROVAL: {
        mode: 2
      },
      PICK_UP_CUSTOMER_DEMAND: {
        utensilCondimentMode
      },
      PICK_UP_FIRE: {
        mode: kitchenCapacityMode
      },
      PICK_UP_HOURS: pickup_hours,
      PICK_UP_POS_DEVICE_SNS: pickup_pos_device_sns,
    }
    console.log(result, 'result')
    setLoading(true)
    const res = await ApiOnlineOrderConfigSave(result);
    if (res.code === 0) {
      message.success(res.message);
      setIsEdit(false);
      await fetchDetail();
    }
    setLoading(false)
  }

  // const handleChangePickupHours = (event, item, field, weekIndex) => {
  //   const _pickupHoursList = [...pickupHoursList]
  //   if (field === 'openSwitch') {
  //     _pickupHoursList.find(x => x.day === item.day).openSwitch = event.target.value;
  //   } else if (field.includes('intervalTimes')) {
  //     const _hoursRangeIndex = field.split('_')[1];
  //     _pickupHoursList.find(x => x.day === item.day).intervalTimes[_hoursRangeIndex] = { startTime: event[0], endTime: event[1] };
  //   }
  //   setPickupHoursList(_pickupHoursList)
  //   form.validateFields([`pickupHours_${ weekIndex }`])
  // }

  const handleBlurQuoteTime = (e, field) => {
    setTimeout(() => {
      if (form.getFieldValue(field) === null || form.getFieldValue(field) === '') {
        form.setFieldValue(field, 0);
      }
      form.validateFields(['quoteTimeMode']);
    }, 0)
  }

  const getDisabled = (val) => {
    if (!isEdit) return true;
    return val;
  }


  return (
    <Spin spinning={loading} wrapperClassName='spin-wrap'>
      <div className='online-orders-pickup-wrap'>
        {/* URL 显示部分保持独立 */}
        <div className='url-display' style={{ paddingBottom: '24px', width: '70vw' }}>
          <span className='url-text'>{i18n.t('PC_OO_URL')}: </span>
          <span className='url-link'>{form.getFieldValue('pickup_online_order_url')}</span>
          <CopyOutlined
            onClick={() => {
              handleUrlCopy();
            }}
            style={{ paddingLeft: '8px', cursor: 'pointer' }}
          />
          <FormOutlined onClick={() => setIsModalOpen(true)} style={{ paddingLeft: '10px' }} />

          {/* 使用弹窗组件 */}
          <PickupUrlDialog
            isOpen={isModalOpen}
            onClose={() => setIsUrlModalOpen(false)}
            onOk={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
            url={form.getFieldValue('pickup_online_order_url')}
            onUrlChange={handleUrlChange}
            i18n={i18n}
          />
        </div>

        <div className="form-wrap">
          <Form form={ form } layout="vertical" requiredMark={ false } disabled={ !isEdit }>
            <Form.Item name={ 'pickUpSwitch' } label={ i18n.t('pc_online_orders_pickup') }>
              <Radio.Group options={ [{ value: true, label: i18n.t('pc_online_orders_on') }, { value: false, label: i18n.t('pc_online_orders_off') }] } style={ { display: 'grid' } }/>
            </Form.Item>
            <Form.Item name={ 'schedulingOrdersMode' } label={ i18n.t('pc_online_orders_scheduling_orders') }>
              <Radio.Group
                options={ ScheduleOrderOptions }
                style={ { display: 'grid' } }
              />
            </Form.Item>

            <Form.Item label={ i18n.t('PC_OO_Quotetime_Strategy') || 'Quote Time Strategy' } style={{ position: 'relative' }}>
              <div style={{ marginBottom: 10, color: '#676768', width: '70vw' }}>{ i18n.t('PC_OO_QuoteTimeDescription')}</div>
              <Form.Item name={ 'quoteTimeMode' }  rootClassName="quote-time-mode-wrap" rules={ formRules.quoteTimeMode }>
                <Radio.Group style={ { display: 'grid' } }>
                  <Radio value={ QUOTE_TIME_ENUM.CUSTOM }>{ i18n.t(OO_QUOTE_TIME_ENUM_I18N[QUOTE_TIME_ENUM.CUSTOM]) || 'Manual' }</Radio>
                  <Radio value={ QUOTE_TIME_ENUM.ORDER_PRICE }>{ i18n.t(OO_QUOTE_TIME_ENUM_I18N[QUOTE_TIME_ENUM.ORDER_PRICE]) || 'Order Price' }
                    <Popover
                      placement="rightTop"
                      content={
                        i18n.t('PC_OO_Quotetime_Order_Price_Description') || 'Adjust quote time based on order amount, different price ranges can correspond to different quote time strategies.'
                      }
                      trigger="hover"
                      overlayStyle={{ width: '500px' }}
                    >
                      <ExclamationCircleOutlined style={{ 'marginLeft': '10px' }} />
                    </Popover>
                  </Radio>
                  <div className="quote-time-input-wrap">
                    <Form.Item name={ 'quoteTimeCustomMin' }>
                      <InputNumber
                        addonAfter={ i18n.t('pc_online_orders_hour') } min={ 0 } max={ 999 } precision={ 0 } style={ { width: 150 } }
                        disabled={ getDisabled(watchedQuoteTimeMode === QUOTE_TIME_ENUM.ASAP) }
                        onBlur={ (e) => handleBlurQuoteTime(e, 'quoteTimeCustomMin') }
                      />
                    </Form.Item>
                    <Form.Item name={ 'quoteTimeCustomHour' }>
                      <InputNumber
                        addonAfter={ i18n.t('pc_online_orders_min') } min={ 0 } max={ 999 } precision={ 0 } style={ { width: 150 } }
                        disabled={ getDisabled(watchedQuoteTimeMode === QUOTE_TIME_ENUM.ASAP) }
                        onBlur={ (e) => handleBlurQuoteTime(e, 'quoteTimeCustomHour') }
                      />
                    </Form.Item>
                  </div>

                  {/* 动态显示输入栏 */}
                  <Form.Item
                    name="orderPriceRules"
                    label={i18n.t('PC_OO_Order_Price_Rules')}
                    getValueFromEvent={(value) => value}
                    preserve // 即使字段隐藏也保留值
                  >
                    {watchedQuoteTimeMode === QUOTE_TIME_ENUM.ORDER_PRICE && (
                      <OrderPriceRules
                        value={form.getFieldValue('orderPriceRules')}
                        onChange={(value) => form.setFieldValue('orderPriceRules', value)}
                        disabled={!isEdit}
                        onValidationChange={(error, warning) =>
                          setValidationStatus({ hasError: error, hasWarning: warning })
                        }
                      />
                    )}
                  </Form.Item>
                </Radio.Group>
              </Form.Item>
            </Form.Item>



            <Form.Item label={ i18n.t('PC_OO_KitchenCapacityManagement') }>
              <div style={{ marginBottom: 10, color: '#676768', width: '70vw' }}>{ i18n.t('PC_OO_KitchenCapacityManagementDescription')}</div>
              <Form.Item name={ 'kitchenCapacityMode' }>
                <Radio.Group style={ { display: 'grid' } }>
                  <Radio value={ KITCHEN_CAPACITY_ENUM.AUTO }>{ i18n.t(KITCHEN_CAPACITY_ENUM_I18N[KITCHEN_CAPACITY_ENUM.AUTO]) }</Radio>
                  <Radio value={ KITCHEN_CAPACITY_ENUM.MANUALLY }>
                    { i18n.t(KITCHEN_CAPACITY_ENUM_I18N[KITCHEN_CAPACITY_ENUM.MANUALLY]) }
                    <Popover
                      placement="rightTop"
                      content={
                        i18n.t('PC_OO_ManualFireDescription')
                      }
                      trigger="hover"
                      overlayStyle={{ width: '500px' }}
                    >
                      <ExclamationCircleOutlined style={{ 'marginLeft': '10px' }} />
                    </Popover>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Form.Item>
            <Form.Item name={ 'utensilCondimentMode' } label={ i18n.t('PC_OO_Utensil') }>
              <Radio.Group style={ { display: 'grid' } }>
                <Radio value={ UTENSIL_ENUM.UTENSIL_YES }>{ i18n.t(UTENSIL_ENUM_I18N[UTENSIL_ENUM.UTENSIL_YES]) }</Radio>
                <Radio value={ UTENSIL_ENUM.UTENSIL_NO }>{ i18n.t(UTENSIL_ENUM_I18N[UTENSIL_ENUM.UTENSIL_NO]) }</Radio>
              </Radio.Group>
            </Form.Item>
            <PickupHours />
            <Form.Item name='pickup_pos_device_sns' label= {<span>{ i18n.t('pc_device_link_to_POS_station') }</span>} >
              <Select
                mode="multiple"
                style={{ width: '360px' }}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                notFoundContent={deviceListLoading ? <Spin size="small" /> : null}
                options={deviceList}
                allowClear
              />
            </Form.Item>
          </Form>
        </div>
        <div className="action-wrap">
          { isEdit ?
            <>
              <Button onClick={ handleCancel }>{ i18n.t('cancel') }</Button>
              <Button type="primary" onClick={ handleSave } loading={ loading }>{ i18n.t('save') }</Button>
            </>
            : <Button type="primary" onClick={ handleEdit } key={ 'edit' }>{ i18n.t('edit') }</Button>
          }
        </div>
      </div>
    </Spin>
  )
}